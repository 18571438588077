import React from "react";
import Layout from "../components/Layout/Layout";
import { Box, Grid, Card, CardMedia, Typography } from "@mui/material";
import Ty from "../components/Layout/MiComponente";
import Figura from "../components/Piaces/Figura";
import a from "./../images/a.webp";
import b from "./../images/b.webp";
import c from "./../images/6.webp";
import d from "./../images/d.webp";
import e from "./../images/e.webp";
import f from "./../images/restaurante.webp";
import newImage from "./../images/Feliz_Año.svg"; // Asegúrate de que el nombre de la imagen sea "Feliz_Año.webp"
import imagen from "./../images/claro.webp";

const About = () => {
  return (
    <Layout>
      <Box
        sx={{
          my: 8,
          textAlign: "center",
          p: 2,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#D32F2F",
            fontSize: "6rem",
            fontWeight: "bold",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            marginY: "1rem",
            "@media (max-width: 768px)": {
              fontSize: "3.6rem", // Ajusta el tamaño de fuente para pantallas más pequeñas
            },
            "@media (max-width: 420px)": {
              fontSize: "3rem", // Ajusta aún más el tamaño de fuente para pantallas muy pequeñas
            },
            "@media (max-width: 390px)": {
              fontSize: "2.4rem", // Ajusta el tamaño de fuente para pantallas extremadamente pequeñas
            },
          }}
        >
          Restaurante Asia
        </Typography>
        <Ty />
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${imagen})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          width: "80%",
          margin: "0 auto",
          padding: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "justify",
            my: 2,
            fontSize: "1.2rem",
            color: "black",
          }}
        >
          Bienvenido a Restaurante Asia. Estamos emocionados de ofrecerle una
          experiencia culinaria auténtica de la cocina china en el corazón de la
          ciudad.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "justify",
            my: 2,
            fontSize: "1.2rem",
            color: "black",
          }}
        >
          En nuestro restaurante, se sumergirá en un mundo de sabores
          tradicionales y exquisitos. Nuestra carta incluye una amplia selección
          de platos picantes y suaves para satisfacer todos los gustos. Desde el
          clásico arroz chowfan hasta el delicioso chopsuey con vegetales, hay
          algo para todos.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "justify",
            my: 2,
            fontSize: "1.2rem",
            color: "black",
          }}
        >
          Para los más pequeños, ofrecemos un menú infantil que incluye deliciosas
          croquetas de pollo en salsa agridulce. Hacemos que la experiencia
          gastronómica sea memorable para toda la familia.
        </Typography>
      </Box>
      <Figura />
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px', // Ajusta el tamaño de fuente para pantallas más pequeñas
    color: '#888', // Cambia el color del texto a uno menos importante
    margin: '20px 0',
  }}
>
  <h1
    style={{
      fontSize: '18px', // Tamaño de fuente original
      margin: '0',
    }}
  >
    Asia restaurante chino
  </h1>
</Box>
      <Grid container spacing={2} justifyContent="center">
        {[
          { image: a, alt: "Imagen 1" },
          { image: b, alt: "Imagen 2" },
          { image: c, alt: "Imagen 3" },
          { image: d, alt: "Imagen 4" },
          { image: e, alt: "Imagen 5" },
          { image: f, alt: "Imagen 6" },
        ].map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={item.image}
                alt={item.alt}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4, py: 4 }}>
        <img
          src={newImage}
          alt="Feliz Año"
          style={{
            maxWidth: "100%",
            height: "auto",
            width: "28rem",
          }}
        />
      </Box>
      <Figura />
    </Layout>
  );
};
export default About;

