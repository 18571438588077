import React, { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "../../images/logoasiablanconavbar1.webp";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, Link } from "react-router-dom";
import "../../styles/HeaderStyles.css";
import { useSelector } from "react-redux";


const Header = () => {
const [mobileOpen, setMobileOpen] = useState(false);
const { cartTotalQuantity } = useSelector((state) => state.cart);
  // handle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box sx={{ textAlign: "center" }}>
<Link to="/" className="logo-link">
  <img src={Logo} alt="logo" className="logo" />
</Link>
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <NavLink activeClassName="active" to={"/"}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={"/menu"}>Menú</NavLink>
        </li>
        <li>
          <NavLink to={"/about"}>Nosotros</NavLink>
        </li>
        <li>
          <NavLink to={"/contact"}>Contacto</NavLink>
        </li>
      </ul>
   
    </Box>
  );
  return (
    <>
      <AppBar component={"nav"} sx={{ bgcolor: "black" }}>
        <Toolbar>
          {/* Logotipo siempre a la izquierda */}
          <Typography
            color={"goldenrod"}
            variant="h6"
            component="div"
          >
<Link to="/" className="logo-link">
  <img src={Logo} alt="logo" className="logo" />
</Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} /> {/* Espacio en blanco para empujar elementos a la derecha */}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ul className="navigation-menu">
              <li>
                <NavLink activeClassName="active" to={"/"}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to={"/menu"}>Menú</NavLink>
              </li>
              <li>
                <NavLink to={"/about"}>Nosotros</NavLink>
              </li>
              <li>
                <NavLink to={"/contact"}>Contacto</NavLink>
              </li>
            </ul>
          </Box>
          {/* Icono de menú para dispositivos móviles */}
    
          <Box sx={{ display: { sm: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "240px",
              backgroundColor: "black",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </>
  );
};
export default Header;
