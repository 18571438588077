import React from "react";
import Layout from "../components/Layout/Layout";
import "../styles/menu_cuadros.css";
import image1 from "./../images/1.webp";
import imageChowFan from "./../images/Chow-Fan.webp";
import imageEntradas from "./../images/Entradas.webp";
import imageChow from "./../images/Chow.webp";
import imageChowFan2 from "./../images/ChowFan.webp";
import imageFan from "./../images/Fan.webp";
import imageToFu from "./../images/ToFu.webp";
import imagePlatoEspecial from "./../images/PlatoEspecial.webp";
import { useEffect, useState } from 'react';
import Board from '../components/Board/Board';
import "./Menuinfantil.css";
import { useSelector } from "react-redux";
import {  Link } from 'react-router-dom';

import { useGetAllProductsQuery } from "../slices/productsApi";
import "../styles/Home.css";
import {  Typography } from "@mui/material";
import Figura from "../components/Piaces/Figura";

const imageList = [image1, imageChowFan, imageEntradas, imageChow, imageChowFan2, imageFan, imageToFu, imagePlatoEspecial];

const MenuInfantil = () => {
  const { items: products, status } = useSelector((state) => state.products);

  const { data, error, isLoading } = useGetAllProductsQuery();
  const filteredProducts = data ? data.filter((infantilProducts) => infantilProducts.category === "infantil") : [];

  const [shuffledMemoBlocks, setShuffledMemoBlocks] = useState([]);
  
  const [selectedMemoBlock, setselectedMemoBlock] = useState(null);
  const [animating, setAnimating] = useState(false);
  const [gameKey, setGameKey] = useState(0);
  
  useEffect(() => {
    const shuffledImageList = shuffleArray([...imageList, ...imageList]);
    setShuffledMemoBlocks(shuffledImageList.map((image, i) => ({ index: i, image, flipped: false })));
  }, [gameKey]);

  const shuffleArray = a => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };

  const handleMemoClick = memoBlock => {
    const flippedMemoBlock = { ...memoBlock, flipped: true };

    let shuffledMemoBlocksCopy = [...shuffledMemoBlocks];
    shuffledMemoBlocksCopy.splice(memoBlock.index, 1, flippedMemoBlock);

    setShuffledMemoBlocks(shuffledMemoBlocksCopy);

    if (selectedMemoBlock === null) {
      setselectedMemoBlock(memoBlock);
    } else if (selectedMemoBlock.image === memoBlock.image) {
      setselectedMemoBlock(null);
    } else {
      setAnimating(true);
      setTimeout(() => {
        shuffledMemoBlocksCopy.splice(memoBlock.index, 1, memoBlock);

        shuffledMemoBlocksCopy.splice(selectedMemoBlock.index, 1, selectedMemoBlock);
        setShuffledMemoBlocks(shuffledMemoBlocksCopy);
        setselectedMemoBlock(null);
        setAnimating(false);
      }, 1000);
    }
  };

  const handleResetGame = () => {
    setGameKey(prevKey => prevKey + 1);
  };

  return (
    <Layout>
      <div className="contenedor_menu _menu " id="ifantil">


<div className="home-containere">
        {status === "success" ? (
          <>
 <Typography
            variant="h1"
            sx={{
              marginTop: "0",
              fontSize: "6rem",
              fontWeight: "bold",
              textAlign:"center",
              color: "#D32F2F",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              "@media (max-width: 768px)": {
                fontSize: "3.6rem",
              },
              "@media (max-width: 420px)": {
                fontSize: "3rem",
              },
              "@media (max-width: 390px)": {
                fontSize: "2.4rem",
              },
            }}
          >
         Nuestro menú infantil
          </Typography>
            <h2>Menú mágico para niños</h2>
            <Link to="/menu" className="btn-regresare"
             sx={{
            marginTop: "1rem",
              fontSize: "6rem",
              textAlign:"center",
            }}
            >Regresar al Menú</Link>
<div style={{ marginTop: '20px' }}>
            <Figura />
          </div>
            <div className="products">
              {data && filteredProducts.map((infantilProducts)  => (
                  <div key={infantilProducts.id} className="product">
                  <span className="plato">{infantilProducts.plato}</span>
                  <h3>{infantilProducts.name}</h3>
                    <img src={infantilProducts.image} alt={infantilProducts.name} />
                    <div className="details">
                      <span>{infantilProducts.desc}</span>
                    </div>
                    <div className="details">
                      <span className="price">${infantilProducts.price}</span>
                    </div>
                  
         
                  </div>
                ))}
            </div>
          </>
          
        ) : status === "pending" ? (
          <p>Cargando...</p>
        ) : (
          <p>Se produjo un error inesperado...</p>
        )}

        <h3 className="led-title" style={{
          display: "inline-block",
          marginTop: "40px",
          fontSize: "44px",
          animation: "colorChange 3s infinite alternate",  // Animación de cambio de color
        }}>Juegemos</h3>

        <Board key={gameKey} memoBlocks={shuffledMemoBlocks} animating={animating} handleMemoClick={handleMemoClick} />

        <button onClick={handleResetGame}    style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          color: "black",
          borderRadius: "8px",
          fontSize: "14px",
        }}   >Reiniciar Juego</button>

<p className="descripcion">
  Nos complace ofrecerles un menú infantil chino, especialmente diseñado para satisfacer los gustos de los más pequeños. Nuestro menú infantil combina sabores familiares con toques chinos únicos para crear una experiencia de sabor emocionante.
</p>
<p className="descripcion">
  Entre nuestras opciones de menú infantil, encontrará el "Combinado Pequeño", que incluye papas a la francesa con nuggets de pollo, pescado o cerdo, acompañado de salsa agridulce para sumergir. También ofrecemos cremas de pollo como opción de sopa.
</p>
<p className="descripcion">
  En nuestro restaurante, nos tomamos muy en serio la calidad de nuestros ingredientes y la preparación de nuestras comidas, especialmente para nuestros huéspedes más jóvenes. Por eso, nos aseguramos de utilizar solo los ingredientes más frescos y saludables en nuestras preparaciones.
</p>
<p className="descripcion">
  Además, ofrecemos bebidas para niños, como Coca Cola, Te Hatsu y Postobón, para acompañar sus comidas.
</p>
<p className="descripcion">
  ¡Traiga a sus hijos a experimentar una deliciosa aventura culinaria en el Restaurante Asia! Estamos seguros de que se irán con una sonrisa en su rostro y el estómago lleno de comida deliciosa. ¡Esperamos verlos pronto!
</p>

        <Link to="/menu" className="btn-regresar">Regresar al menú</Link>
      </div>
      </div>
    </Layout>
    
  );
};

export default MenuInfantil;
