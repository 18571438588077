import React from 'react';
import './MemoBlock.css';

const MemoBlock = ({ animating, handleMemoClick, memoBlock }) => (
  <div className="memo-block" onClick={() => (!memoBlock.flipped && !animating) && handleMemoClick(memoBlock)}>
    <div className={`memo-block-inner ${memoBlock.flipped && 'memo-block-flipped'}`}>
      <div className="memo-block-front"></div>
      <div className="memo-block-back">
        {memoBlock.flipped && <img src={memoBlock.image} alt="Memo Block" />}
      </div>
    </div>
  </div>
);

export default MemoBlock;
