import React from "react";
import "../../styles/Horarios.css";

const Horarios = () => {
  return (
    <div className="horarios-container">
      <h2>Horarios de Operación</h2>
      <div className="horario-item">
        <p>Lunes - Sábado</p>
        <p>11:00 AM - 10:30 PM</p>
      </div>
      <div className="horario-item">
        <p>Domingos y Festivos</p>
        <p>11:00 AM - 10:00 PM</p>
      </div>
    </div>
  );
};

export default Horarios;

