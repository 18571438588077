import React from "react";
import { MenuList } from "../data/data";
import { Link } from "react-router-dom";
import Layout from "./../components/Layout/Layout";
import { Box, Card, CardContent, CardMedia, Button, Typography } from "@mui/material";

const Menu = () => {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            height: "300px",
            width: "100%",
            margin: "0",
            padding: "0",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
            "@media (max-width: 768px)": {
              height: "200px",
              fontSize: "1.6rem",
            },
            "@media (max-width: 420px)": {
              height: "180px",
              fontSize: "1.3rem",
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
                          marginTop: "1rem",
              fontSize: "6rem",
              fontWeight: "bold",
              color: "#D32F2F",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              "@media (max-width: 768px)": {
                fontSize: "3.6rem",
              },
              "@media (max-width: 420px)": {
                fontSize: "3rem",
              },
              "@media (max-width: 390px)": {
                fontSize: "2.4rem",
              },
            }}
          >
            Menú
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {MenuList.map((menu) => (
            <Card
              sx={{
                maxWidth: "380px",
                width: "24rem",
                margin: "20px",
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "26rem",
                minHeight: "26rem",
              }}
              key={menu.name}
            >
              <Link to={menu.link}>
                <CardMedia
                  sx={{ height: "280px" }}
                  component={"img"}
                  src={menu.image}
                  alt={menu.name}
                  loading="lazy" // Agregar lazy load para la imagen
                />
              </Link>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  variant="h2"
                  component={"div"}
                  sx={{
                    marginBottom: "2px",
                    alignItems: "center",
                    fontSize: "1.8rem",
                    color: "#550000",
                  }}
                >
                  {menu.name}
                </Typography>
              <Typography variant="body2" style={{ fontSize: '1rem' }}>
  {menu.description}
</Typography>
                <Link to={menu.link}>
<Button
  style={{
    textAlign: "center",
    backgroundColor: "#D32F2F", // Un rojo oscuro que puede ser adecuado para un restaurante chino
    color: "#FFFFFF", // Texto en blanco para contraste
    textTransform: "lowercase", // Convertir el texto a minúsculas
    padding: "4px 8px", // Espaciado interior del botón
    borderRadius: "16px", // Borde redondeado
    fontSize: "0.8rem",
    border: "none",
    cursor: "pointer",
    marginTop: "8px", // Margen de 8px en todos los lados del botón
  }}
>
  {menu.gelemento}
</Button>

                </Link>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
      <div
        className="nota-factura"
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          borderRadius: "8px",
          marginTop: "20px",
          fontSize: "14px",
        }}
      >
        <p className="letra-pequeña">
          *Advertencia de propina: Se informa a los consumidores que este
          establecimiento de comercio sugiere a sus consumidores una propina
          correspondiente al 10% del valor de la cuenta, la cual puede ser
          aceptada, rechazada o modificada por usted, de acuerdo a su valoración
          del servicio prestado. Al momento de solicitar la cuenta, indíquele a
          la persona que lo atiende si desea que dicho valor sea o no incluido
          en la factura o indíquele el valor que desea dar como propina.
        </p>
        <p>
          En este establecimiento de comercio, los dineros recogidos por
          concepto de propina se destinarán en un 100% a todo el personal
          involucrado en la cadena del servicio.
        </p>
        <p>
          En caso de que tenga algún inconveniente con el cobro de su propina,
          comuníquese con la línea exclusiva dispuesta en Bogotá para atender
          las inquietudes sobre el tema: 6513240 o a la línea de atención al
          ciudadano de la Superintendencia de Industria y Comercio: 5920400 en
          Bogotá o para el resto del país, línea gratuita nacional:
          018000-910165 para que radique su queja.
        </p>
      </div>
    </Layout>
  );
};

export default Menu;