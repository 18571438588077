import React from "react";
import Layout from "../components/Layout/Layout";
import { categorizedProducts } from "../products/products";
import "../sty/cartaschinas.css"; // Asegúrate de reemplazar ".css" con el nombre correcto de tu archivo CSS
import { Typography } from "@mui/material";
import Figura from "../components/Piaces/Figura";
import { Link } from 'react-router-dom';

const PlatosEspeciales = () => {
  const filteredEspeciales = categorizedProducts.especiales || [];
  const evenProducts = filteredEspeciales.filter((_, index) => index % 2 === 0);
  const oddProducts = filteredEspeciales.filter((_, index) => index % 2 !== 0);

  return (
    <Layout>
      <div className="contenedor_cartas">
        <div style={{ marginTop: '20px' }}>
          <Figura />
        </div>
        <div className="cartas-container">
          <h2 className="carta-title">Platos Especiales</h2>
          <ul className="product-list">
            <div className="product-column">
              {evenProducts.map((product) => (
                <li className="product-item" key={product.id}>
                  <Typography variant="h3" className="product-name">{product.name}</Typography>
                  <p className="product-description">{product.desc}</p>
                  <p className="product-price"> ${product.price}</p>
                </li>
              ))}
            </div>
            <div className="product-column">
              {oddProducts.map((product) => (
                <li className="product-item" key={product.id}>
                  <Typography variant="h3" className="product-name">{product.name}</Typography>
                  <p className="product-description">{product.desc}</p>
                  <p className="product-price"> ${product.price}</p>
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Figura />
        </div>
        <Link to="/menu" className="btn-regresare"
          sx={{
            marginTop: "1rem",
            fontSize: "6rem",
            textAlign: "center",
          }}
        >Regresar al Menú</Link>
      </div>
    </Layout>
  );
};

export default PlatosEspeciales;

