const products = [
  
  {
    id: 1,
    category: "lomien",
    plato:"Lomien",
    name: "Pescado",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Rica pasta china con tiernos y deliciosos trozos de pescado.",
    price:  59000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "lomien de pescado 鱼"
  },
  {
    id: 2,
    category: "lomien",
    plato:"Lomien",
    name: "Pollo",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Pasta china de delicioso pollo.",
    price:  56000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "lomien de pollo 鸡"
  },
  {
    id: 3,
    category: "lomien",
    plato:"Lomien",
    name: "Res",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    desc: "Pasta china de res seleccionada.",
    price:  58000,
   caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
   alt: "lomien de res 牛肉"
  },
    {
    id: 4,
    category: "lomien",
    plato:"Lomien",
    name: "Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    desc: "Deliciosos camarones en Lomien.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de camarones 虾"
  },
    {
    id: 6,
    category: "lomien",
    plato:"Lomien",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    desc: "Rica pasta china con inigualables y deliciosos langostinos.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
// fin de lomien

// inicio de Mariscos
    {
    id: 7,
    category: "Mariscos",
    plato:"Cazuela",
    name: "Mariscos",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Exquisita cazuela con una variedad de mariscos como pescado, langostinos y camarones frescos en salsa roja de la casa.",
    price:  70000,
    
  },
      {
    id: 8,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Langostino a la Plancha",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    desc: "Langostinos frescos a la plancha con papa frita y ensalda.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
      {
    id: 9,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Camarones al Ajillo",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    desc: "Camarones salteados al ajillo con aceite de oliva y especias papa al vapor y mantequilla.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
      {
    id: 10,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Langostino a la Mariposa",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    desc: "Deliciosos langostinos preparados a la mariposa y sazonados con especias aromáticas más tocineta y papas a la francesa.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
        {
    id: 100,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Langostino al Ajillo",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    desc: "Langostinos salteados al ajillo con aceite de oliva y especias papa al vapor y mantequilla.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
      {
    id: 11,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Pescado a la Milanesa",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Pescado empanizado y dorado a la perfección, acompañado de papas fritas.",
    price:  59000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "lomien de pescado 鱼"
  },
        {
    id: 111,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Pescado a la Plancha",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Filete de pescado a la plancha con papas fritas y ensalada.",
    price:  59000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "lomien de pescado 鱼"
  },
      {
    id: 12,
    category: "Mariscos",
    plato:"Mariscos",
    name: "Pescado Vegetales",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Filete de pescado fresco acompañado de vegetales salteados en salsa de soya.",
    price:  59000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "lomien de pescado 鱼"
  },
// fin de mariscos

// inicio de arroz

 {
    id: 133,
  category: "arroz",
  plato:"Arroz",
    name: "Especial",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Arroz chino especial con Pollo, Cerdo, Jamón.",
    price:  53000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Arroz chino"
  },

 {
    id: 1333,
  category: "arroz",
  plato:"Arroz",
    name: "Especial 1/2",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Arroz chino especial con Pollo, Cerdo, Jamón.",
    price:  37000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "arroz especial"
  },

  {
    id: 1333,
  category: "arroz",
  plato:"Arroz",
    name: "Especial 1/2 + Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Arroz chino especial con Pollo, Cerdo, Jamón + Camarones.",
    price:  44000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "arroz 1/2 camarones"
  },

  {
    id: 13,
  category: "arroz",
  plato:"Arroz",
    name: "Pescado",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Arroz chino con deliciosos trozos de Pescado.",
    price:  55000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "Arroz pescado"
  },

  {
    id: 14,
  category: "arroz",
  plato:"Arroz",
    name: "Pollo",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Arroz frito chino de delicioso Pollo.",
    price:  55000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Arroz pollo"
  },

  {
    id: 144,
  category: "arroz",
  plato:"Arroz",
    name: "8 Sabores",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Arroz salteado con Langostinos, Res, Pollo, Camarones, Cerdo y Jamón.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Arroz 8 sabores"
  },


  {
    id: 15,
   category: "arroz",
  plato:"Arroz",
    name: "Res",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    brand: "res",
    desc: "Arroz frito con deliciosos trozos de Res.",
    price:  58000,
   caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
   alt: "lomien de res 牛肉"
  },
    {
    id: 16,
   category: "arroz",
  plato:"Arroz",
    name: "Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    brand: "camarones",
    desc: "Deliciosos arroz frito con camarones.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de camarones 虾"
  },

    {
    id: 18,
category: "arroz",
  plato:"Arroz",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    brand: "Langostino",
    desc: "Arroz frito chino e inigualables y deliciosos langostinos.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },

  {
    id: 19,
  category: "chop",
  plato:"Chopsuey",
    name: "Especial",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Vegetales en salsa soya china con tiernos y deliciosos trozos de pescado.",
    price:  53000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Chop Suey"
  },

    {
    id: 19999,
  category: "chop",
  plato:"Chopsuey",
    name: "Especial + Camerones",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Vegetales en salsa soya china con tiernos y deliciosos trozos de pescado.",
    price:  62000,
     caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Chop Suey"
  },

    {
    id: 199,
  category: "chop",
  plato:"Chopsuey",
    name: "Especial 1/2 + camarones",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Vegetales en salsa soya con deliciosos trozos de pescado.",
    price:  44000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Chop Suey"
  },
  {
    id: 20,
  category: "chop",
  plato:"Chopsuey",
    name: "Pollo",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Vegetales en salsa soya y delicioso pollo.",
    price:  55000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "lomien de pollo 鸡"
  },
  {
    id: 21,
  category: "chop",
  plato:"Chopsuey",
    name: "Res",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    brand: "res",
    desc: "Vegetales en salsa soya y res seleccionada.",
    price:  58000,
   caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
   alt: "lomien de res 牛肉"
  },
    {
    id: 22,
     category: "chop",
  plato:"Chopsuey",
    name: "Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    brand: "camarones",
    desc: "Deliciosos camarones en vegetales y salsa soya.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de camarones 虾"
  },
    {
    id: 24,
  category: "chop",
  plato:"Chopsuey",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    brand: "Langostino",
    desc: "Vegetales en salsa soya con inigualables y deliciosos langostinos.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
// finalizar chopsuey 


// inicio de agridulce

  {
    id: 25,
  category: "agridulce",
  plato:"Agridulce",
    name: "Pescado",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Salsa Agridulce con deliciosos trozos de Pescado.",
    price:  55000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "Agridulce de pescado"
  },
  {
    id: 26,
 category: "agridulce",
  plato:"Agridulce",
    name: "Pollo",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Croquetas de Pollo en Salsa Agridulce.",
    price:  55000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "agridulce de pollo"
  },
    {
    id: 28,
  category: "agridulce",
  plato:"Agridulce",
    name: "Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    brand: "camarones",
    desc: "Deliciosos Camarones en Salsa Agridulce.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de camarones 虾"
  },
    {
    id:29,
 category: "agridulce",
  plato:"Agridulce",
    name: "Cerdo",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/lpqnwbhqibeqvoqbugjm.webp",
    brand: "cerdo",
    desc: "Salsa Agridulce con Croquetas de Cerdo.",
    price:  55000,
   caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825396/Asia/j9h9nev4wfth6jyqsg2k.webp",
   alt: "lomien de cerdo 猪"
  },
    {
    id: 30,
 category: "agridulce",
  plato:"Agridulce",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    brand: "Langostino",
    desc: "Rica Salsa Agridulce china con inigualables y deliciosos Langostinos.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
// fin de agridulce

// inicio de ostras
  {
    id: 31,
  category: "ostras",
  plato:"Salsa de Ostras",
    name: "Pescado",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/rguviclmrs9h5pzfcivr.webp",
    desc: "Rica Salsa de Ostras, raíces chinas, cebolla con deliciosos trozos de Pescado.",
    price:  59000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/rxf1rh38wqycgdsg1lkx.svg",
    alt: "lomien de pescado 鱼"
  },
  {
    id: 32,
 category: "ostras",
  plato:"Salsa de Ostras",
    name: "Pollo",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Salsa de Ostras, raíces chinas, cebolla con Pollo.",
    price:  57000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "lomien de pollo 鸡"
  },
  {
    id: 33,
  category: "ostras",
  plato:"Salsa de Ostras",
    name: "Res",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    brand: "res",
    desc: "Salsa de Ostras, raíces chinas, cebolla con cortes de Res seleccionada.",
    price:  58000,
   caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
   alt: "lomien de res 牛肉"
  },
    {
    id: 34,
   category: "ostras",
  plato:"Salsa de Ostras",
    name: "Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    brand: "camarones",
    desc: "Deliciosos Camarones, raíces chinas, cebolla en Salsa Ostras.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de camarones 虾"
  },
    {
    id:35,
  category: "ostras",
  plato:"Salsa de Ostras",
    name: "Cerdo",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/lpqnwbhqibeqvoqbugjm.webp",
    brand: "cerdo",
    desc: "Salsa de Ostras, raíces chinas, cebolla con Cerdo.",
    price:  57000,
   caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825396/Asia/j9h9nev4wfth6jyqsg2k.webp",
   alt: "lomien de cerdo 猪"
  },
    {
    id: 36,
   category: "ostras",
   plato:"Salsa de Ostras",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    brand: "Langostino",
    desc: "Salsa Ostras inigualable, raíces chinas, cebolla y deliciosos langostinos.",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino 大虾"
  },
// fin de ostras 

// inicio de curry 

  {
    id: 38,
   category: "curry",
  plato:"Salsa de Curry",
    name: "Pollo",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Salsa Curry con papa vapor, cebolla y deliciosos trozos de Pollo.",
    price:  56000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "curry pollo"
  },

  {
    id: 39,
   category: "curry",
  plato:"Salsa de Pescado",
    name: "Pescado",
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    desc: "Salsa Curry con papa vapor, cebolla y deliciosos trozos de pescado.",
    price:  59000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "curry pescado"
  },



    {
    id: 40,
   category: "curry",
  plato:"Salsa de Curry",
    name: "Camarones",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/skzpad4idpe8tbxafpdt.webp",
    desc: "Deliciosos camarones en Salsa Curry, papa vapor y cebolla.",
    price:  62000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "Curry de camarones"
  },
  
    {
    id: 42,
  category: "curry",
  plato:"Salsa de Curry",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/ockpg5ieuwbyfbuthb1t.webp",
    desc: "Salsa Curry con papa vapor, cebolla y langostinos",
    price:  72000,
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/hm9lwqcwmlmlz3nv7p4n.webp",
    alt: "lomien de langostino"
  },
// fin de curry 

  // inicio de infantil
    {
    id: 43,
    category: "infantil",
    plato:"Plato Menú",
    name: "Infantil",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/ju2ab69jy1jotkiet8ju.png",
    desc: "Papas a la Francesa y croquetas de pollo con salsa agridulce aparte.",
    price:  58000,
  },
// fin de infatil


// inicio de vegano
  {
    id: 44,
    category: "vegano",
    plato:"Vegano",
    name: "Lomien",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/tcfprwllybemu35ovwpo.png",
    desc: "Rica pasta china con cebolla y salsa de soya.",
    price:  46000,
  },

  {
    id: 45,
    category: "vegano",
    plato:"Vegano",
    name: "Chopsuey",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/xgdq0adpvnh0rklj6mm9.png",
    desc: "Deliciosos vegetales en salsa soya, zanahoria, brócoli, champiñones, cidra, cebolla.",
    price:  46000,
  },
  {
  id: 455,
  category: "vegano",
  plato: "Vegano",
  name: "Chow Mein",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/tcfprwllybemu35ovwpo.png",
  desc: "Un salteado vegetariano con zanahoria, brócoli, cidra, pepino, cebolla y pasta china",
  price: 46000,
},

// fin de vegano


// inicio de vegetariano
 {
  id: 46,
  category: "vegetariano",
  plato: "Vegetariano",
  name: "Tofu sin carne",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/xgdq0adpvnh0rklj6mm9.png",
  desc: "Deleita tus papilas gustativas con nuestro Tofu sin carne, salsa agridulce deliciosa y satisfactoria.",
  price: 46000,
},
{
  id: 47,
  category: "vegetariano",
  plato: "Vegetariano",
  name: "Chopsuey",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/xgdq0adpvnh0rklj6mm9.png",
  desc: "Déjate llevar por los sabores del Chopsuey, un salteado vegetariano con zanahoria, brócoli, cidra, pepino, cebolla.",
  price: 46000,
},
{
  id: 477,
  category: "vegetariano",
  plato: "Vegetariano",
  name: "Chow Mein",
image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/tcfprwllybemu35ovwpo.png",
  desc: "Un salteado vegetariano con zanahoria, brócoli, cidra, pepino, cebolla y pasta china",
  price: 46000,
},
{
  id: 48,
  category: "vegetariano",
  plato: "Vegetariano",
  name: "Arroz chino",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/xgdq0adpvnh0rklj6mm9.png",
  desc: "Deliciosa versión vegetariana del tradicional arroz frito chino.",
  price: 46000,
},
{
  id: 49,
  category: "vegetariano",
  plato: "Vegetariano",
  name: "Lomien",
  image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/tcfprwllybemu35ovwpo.png",
  desc: "Es el sueño hecho realidad para los amantes de la pasta larga china con soya y cebolla. ",
  price: 46000,
},
// fin de vegetariano

// inicio de combinado
  {
    id: 50,
    category: "combinado",
    plato:"Combinado",
    name: "Arroz especial y Chopsuey",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Arroz frito con carne de cerdo, pollo y jamón, vegetales saltados son pollo, cerdo y jamón.",
    price:  53000,
  },
  {
    id: 51,
    category: "combinado",
    plato:"Combinado",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Arroz Especial y Pollo en Salsa Agridulce",
    desc: "Arroz frito con carne de cerdo, pollo y jamón y croquetas de pollo agridulce.",
    price:  53000,
  },
    {
    id: 52,
    category: "combinado",
    plato:"Combinado",
    name: "Arroz y Cerdo Agridulce",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Arroz frito con carne de cerdo, pollo y jamón y croquetas de cerdo agridulce.",
    price:  54000,
  },
  {
    id: 53,
    category:"combinado",
    plato: "Combinado",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Chop Suey y Cerdo en Salsa Agridulce",
    desc: "Vegetales y croquetas de cerdo agridulce.",
    price:  54000,
  },
    {
    id: 54,
 category:"combinado",
  plato: "Combinado",
    name: "Chop Suey y Pollo en Salsa Agridulce",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Vegetales y croquetas de pollo agridulce.",
    price:  54000,
  },

    {
    id: 60,
    category: "triple",
    plato:"Combinado triple",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Arroz especial, Chopsuey  y agridulce de pollo",
    desc: "Arroz frito con carne de cerdo, pollo y jamón, vegetales saltados son pollo, cerdo y jamón, pollo en salsa agridulce",
    price:  54000,
  },
    {
    id:61,
    category: "triple",
    plato:"Combinado triple",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Arroz  especial, Chopsuey y agridulce de cerdo",
    desc: "Arroz frito con carne de cerdo, pollo y jamón, vegetales saltados son pollo, cerdo y jamón, cerdo en salsa agridulce.",
    price:  53000,
  },
// fin de combinado
// inicio tofu
    {
    id:62,
    category: "tofu",
    plato:"ToFu",
    name: "Pollo",
image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Ricos trozos de ToFu con inigualables de delicioso pollo.",
    price:  58000,
  },
      {
    id:63,
    category: "tofu",
    plato:"ToFu",
    name: "Res",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Ricos trozos de ToFu con inigualables y de deliciosos res.",
    price:  60000,
  },
    {
    id:64,
    category: "tofu",
    plato:"ToFu",
    name: "Mapo Tou Fu",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Ricos trozos de tofu con inigualables mezclas de carne, sabores picantes y tradicionales chinos.",
    price:  61000,
   
  },
    {
    id:65,
    category: "tofu",
    plato:"ToFu",
    name: "Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Ricos trozos de ToFu con inigualables y deliciosos langostinos.",
    price:  74000,
  },
// fin de tofu
// inicio de carnes
  {
    id:66,
    category: "carnes",
    plato:"Carnes",
    name: "Res Pimentón",
    desc: "Cortes de Res con pimentón y cebolla en salsa de soya",
    price:  60000,
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
    alt: "Res pimenton"
  },
  {
    id:67,
    category: "carnes",
    plato:"Carnes",
    name: "Pollo Plancha",
    desc: "Rico pollo con papas fritas y ensalada",
    price:  59000,
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "pollo plancha"
  },
  {
    id:68,
    category: "carnes",
    plato:"Carnes",
    name: "Pollo Milanesa",
    desc: "Delicioso Pollo con papas fritas y ensalada",
    price:  59000,
    image:  "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/tfbkrldizjrkda9atnhv.webp",
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/plbxbpjnticxloqyfnpw.webp",
    alt: "Pollo milanesa"
  },
    {
    id:69,
 category: "carnes",
    plato:"Carnes",
    name: "Solomito con Vegetales en Salsa de Vino",
    desc: "Solomito en salsa de vino y vegetales seleccionados.",
    price:  61000,
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
    alt: "Solomito con vegetales en salsa de vino"
    
  },
    {
    id:70,
    category: "carnes",
    plato:"Carnes",
    name: "Filet Mignon con Campiñones",
    desc: "Carne de Res con tocineta en salsa de champiñones, ensalada y papas a la francesa.",
    price:  61000,
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
    alt: "Filet mignon"
  },
    {
    id: 71,
  category: "carnes",
    plato:"Carnes",
    name: "Solomito Asado",
    desc: "Solomito con papas fritas y ensalada",
    price:  61000,
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/zdzjuychot8opgxs30rm.webp",
    caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825424/Asia/xjgwztwsszljfzxh14ai.webp",
    alt: "lomien de res 牛肉"
 
  },
      {
    id: 72,
  category: "carnes",
    plato:"Carnes",
    name: "Cerdo Asado",
    desc: "Corte de tierno cerdo con papas fritas y ensalada",
    price:  55000,
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699587473/Asia/lpqnwbhqibeqvoqbugjm.webp",
     caracter: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699825396/Asia/j9h9nev4wfth6jyqsg2k.webp",
   alt: "Cerdo Asado"
  },
// fin de carnes
//platos especiales
     {
    id: 73,
    category: "especiales",
    plato:"Platos Especiales",
    name: "Pollo en Salsa de Naranja",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Deliciosa pechuga de Pollo en corte de trozos bañada en salsa de Naranja.",
    price:  59000,
  },
      {
    id: 75,
  category: "especiales",
    plato:"Platos Especiales",
    name: "Chaw Pat Chin",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",

    desc: "Langostino, Res, ToFu, Pollo, Camarones, Cerdo, Pescado y variedad de Vegetales.",
    price:  60000,
  },

      {
    id: 76,
    category: "especiales",
    plato:"Platos Especiales",
    name: "Brócoli Pollo",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Pollo en salsa Soya en cama de Brócoli.",
    price:  59000,
  },

   {
    id: 77,
    category: "especiales",
    plato:"Platos Especiales",
    name: "Brócoli Res",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Res en salsa de soya en cama de Brócoli.",
    price:  61000,
  },

     {
    id: 78,
    category: "especiales",
    plato:"Platos Especiales",
    name: "Brócoli Langostino",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Langostino salteado en salsa soya en cama de Brócoli.",
    price:  72000,
  },
        {
    id: 79,
    category: "especiales",
    plato:"Platos Especiales",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    name: "Pollo en Ajonjolí en salsa de Mariscos",
    desc: "Pollo bañado en salsa de mariscos y ajonjolí.",
    price:  59000,
  },
        {
    id: 80,
    category: "especiales",
    plato:"Platos Especiales",
    name: "Langostinos Salteados",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    desc: "Langostinos en aliños.",
    price:  74000,
  },
        {
    id: 81,
    category: "especiales",
    plato:"Platos Especiales",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    name: "Res con jengibre en salsa de Ostras",
    desc: "Deliciosos trozos de res en salsa.",
    price:  60000,
  },
        {
    id: 82,
    category: "especiales",
    plato:"Platos Especiales",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    name: "Camarones con papa al vapor al Ajillo",
    desc: "Ajillo con papa al vapor y camarones.",
    price:  62000,
  },
    {
    id: 83,
    category: "especiales",
    plato:"Platos Especiales",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/vkxcueaakfez9zbfu2aq.png",
    name: "Tocino con Jalapeños",
    desc: "Tocino finamente picado con Jalapeños extrapicantes.",
    price:  61000,
  },
// fin de los especiales 
// sopas
  {
    id: 84,
  category: "sopas",
    plato:"Sopas",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Hot and Sour",
    desc: "Sopa de trozos de carne de cerdo y pollo, Tofu, semi picante.",
    price:  22500,
  },
  {
    id: 85,
  category: "sopas",
    plato:"Sopa",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Wan Tum",
    desc: "Sopa china deliciosa de pasta y carne.",
    price:  22500,
  },
      {
    id: 866,
  category: "sopas",
    plato:"Sopa",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Mariscos",
    desc: "",
    price:  25500,
  },
    {
    id: 86,
  category: "sopas",
    plato:"Sopa",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Yat Ko Mien",
    desc: "Sopa de Pollo con fideos y vegetales.",
    price:  22500,
  },
// finde sopas 
// inicio de cremas 
    {
    id: 87,
  category: "cremas",
    plato:"Crema de",
    name: "Camarones",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Crema con trocitos de Camarones.",
    price:  24000,
  },
    {
    id: 88,
  category: "cremas",
    plato:"Crema de",
    name: "Langostinos",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Crema con trocitos de Langostinos.",
    price:  26500,
  },
    {
    id: 89,
  category: "cremas",
    plato:"Crema de",
    name: "Cebolla",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Crema con trocitos de Cebolla.",
    price:  20500,
  },
    {
    id: 90,
  category: "cremas",
    plato:"Crema de",
    name: "Brócoli",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Crema con trocitos de Brócoli.",
    price:  20500,
  },
    {
    id: 91,
  category: "cremas",
    plato:"Crema de",
    name: "Pollo",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Crema con trocitos de pollo.",
    price:  22500,
  },
    {
    id: 92,
  category: "cremas",
    plato:"Crema de",
    name: "Pescado",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Crema con trocitos de pescado.",
    price:  22500,
  },
// fin de crema
//entradas
    {
    id: 93,
  category: "entradas",
    plato:"Entrada",
    name: "Lumpia",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    desc: "Rollo primavera de carne y vegetales.",
    price:  9500,
  },

   {
    id: 94,
  category: "entradas",
    plato:"Entrada",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Costillas Asadas",
    desc: "Jugosas costillas asadas con salsa barbacoa miel y soya.",
    price:  53000,
  },

   {
    id: 95,
  category: "entradas",
    plato:"Entrada",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Wan Tum Frito",
    desc: "Ojuelas chinas fritas con amor.",
    price:  26500,
  },

   {
    id: 96,
  category: "entradas",
    plato:"Entrada",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Copa de Langostinos",
    desc: "Copa con deliciosos langostinos en salsa rosada.",
    price:  68000,
  },

   {
    id: 93,
  category: "entradas",
    plato:"Entrada",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Copa de Camarón",
    desc: "Copa de ricos y deliciosos camarones en salsa rosada.",
    price: 59000,
  },

   {
    id: 93,
  category: "entradas",
    plato:"Entrada",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Combinación de la Casa",
    desc: "Plato variado de carnes, Pollo, Cerdo, Langostinos, Lumpias, Costillas y Wantum Frito.",
    price: 68000,
  },

    {
    id: 93,
  category: "entradas",
    plato:"Entrada",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/msjumuw7fyhlfl6mddat.png",
    name: "Copa de Huevo de Codorniz",
    desc: "Copa con Huevos de Codorniz.",
    price: 24000,
  },
// fin de entradas
// bebidas
// inicio sodas saborizadas
{
  id: 94,
  category: "sodasaborizada",
  plato: "sodasaborizada",
  name: "Sandía",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 15900,
},
{
  id: 95,
  category: "sodasaborizada",
  plato: "sodasaborizada",
  name: "Maracuyá",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 15900,
},
{
  id: 96,
  category: "sodasaborizada",
  plato: "sodasaborizada",
  name: "Lichys",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 15900,
},
// fin de sodas saborizadas
// inicio postobon
 {
          id: 97,
          category: "gaseosas",
          plato: "Postobon",
          name: "Manzana pequeña",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 8500,
        },
        {
          id: 98,
           category: "gaseosas",
          plato: "Postobon",
          name: "7up pequeña",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 8500,
        },
        {
          id: 99,
           category: "gaseosas",
          plato: "Postobon",
          name: "Colombiana pequeña",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 8500,
        },
           {
          id: 9669,
           category: "gaseosas",
          plato: "Postobon",
          name: "Bretaña pequeña",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 8500,
        },

             {
          id: 9669,
           category: "gaseosas",
          plato: "Postobon",
          name: "Colombiana 1.5 litros",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 8900,
        },
     {
          id: 9669,
           category: "gaseosas",
          plato: "Postobon",
          name: "Manzana 1.5 litros",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 8900,
        },
     {
          id: 9669,
           category: "gaseosas",
          plato: "Postobon",
          name: "7up 2.5 litros",
           image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
          desc: "",
          price: 9900,
        },

  
// fin de postobon
// cocacola
  {
    id: 10660,
 category: "gaseosas",
    plato: "Cocacola",
    name: " Zero pequeña",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 8500,
  },

  {
    id: 102,
     category: "gaseosas",
    plato: "Cocacola",
    name: "Cocacola pequeña",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 8500,
  },

    {
    id: 10077,
 category: "gaseosas",
    plato: "Cocacola",
    name: "Zero 1.5 litros",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 9900,
  },

  {
    id: 10662,
     category: "gaseosas",
    plato: "Cocacola",
    name: "Cocacola 1.5 litros",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 9900,
  },

     {
    id:7878787,
 category: "gaseosas",
    plato: "Cocacola",
    name: "Zero 2.5 litros",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 10900,
  },
  {
    id:668299,
     category: "gaseosas",
    plato: "Cocacola",
    name: "Cocacola 3 litros",
     image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 12600,
  },
// fin de cocacola
{
  id: 10779,
  category: "bebidas",
  plato: "Agua",
  name: "Botella",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 8500,
},
// te hatsu
{
  id: 104,
   category: "hatsu",
  plato: "Té HATSU",
  name: "Té Hatsu Verde",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "Bebida con té verde con sabor a yuzu & manzanilla.",
  price: 11000,
},
{
  id: 105,
  category: "hatsu",
  plato: "Té HATSU",
  name: "Té Hatsu Rosado",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "Bebida con té blanco con sabor a lychee.",
  price: 11000,
},
{
  id: 106,
  category: "hatsu",
  plato: "Té HATSU",
  name: "Té Hatsu Amarillo",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "Bebida con té blanco con sabor a carambolo & flor de loto.",
  price: 11000,
},
{
  id: 107,
   category: "hatsu",
  plato: "Té HATSU",
  name: "Te Hatsu Negro",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "Bebida con té negro & jugo de limón.",
  price: 11000,
},
{
  id: 108,
   category: "hatsu",
  plato: "Té HATSU",
  name: "Té Hatsu Blanco",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "Bebida con té blanco con sabor a mangostino.",
  price: 11000,
},
{
  id: 109,
  category: "hatsu",
  plato: "Té HATSU",
  name: "Té Hatsu Lila",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "Bebida con té blanco con sabor a flor de cerezo.",
  price: 11000,
},
//fin de hatsu
//inicio de soda 
{
  id: 107799,
   category: "sodas",
  plato: "SODA",
  name: "Soda Michelada",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11800,
},
{
  id: 108899,
   category: "sodas",
  plato: "SODA",
  name: "Soda de Lychee",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 14900,
},
{
  id: 100099,
  category: "sodas",
  plato: "SODA",
  name: "Soda Sandia",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 14900,
},
{
  id: 100099,
  category: "sodas",
  plato: "SODA",
  name: "Soda Maracuya",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 14900,
},

//inicio jugos
{
  id: 987,
  category: "jugos",
  plato: "Jugo",
  name: "Limonada Cereza",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 12900,
},
{
  id: 876,
  category: "jugos",
  plato: "Jugo",
  name: "Limonada Coco",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 12900,
},
{
  id: 765,
  category: "jugos",
  plato: "Jugo",
  name: "Limonada Natural",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},
{
  id: 654,
  category: "jugos",
  plato: "Jugo",
  name: "Soda Sandia",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 14900,
},
{
  id: 432,
  category: "jugos",
  plato: "Jugo",
  name: "Fresa",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},
{
  id: 543,
  category: "jugos",
  plato: "Jugo",
  name: "Fresa en Leche",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11900,
},
{
  id: 443,
  category: "jugos",
  plato: "Jugo",
  name: "Guanabana",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},

{
  id: 2346,
  category: "jugos",
  plato: "Jugo",
  name: "Guanabana en Leche",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11900,
},

{
  id: 9998899,
  category: "jugos",
  plato: "Jugo",
  name: "Mango",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},

{
  id: 997878899,
  category: "jugos",
  plato: "Jugo",
  name: "Mango en Leche",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11900,
},

{
  id: 19669899,
  category: "jugos",
  plato: "Jugo",
  name: "Maracuya",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},

{
  id: 129997899,
  category: "jugos",
  plato: "Jugo",
  name: "Maracuya en Leche",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11900,
},

{
  id: 139669899,
  category: "jugos",
  plato: "Jugo",
  name: "Mora",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},

{
  id: 149997899,
  category: "jugos",
  plato: "Jugo",
  name: "Mara en Leche",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11900,
},

{
  id: 159669899,
  category: "jugos",
  plato: "Jugo",
  name: "Mandarina",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 10500,
},
{
  id:169669899,
  category: "jugos",
  plato: "Jugo",
  name: "Mango biche",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 11000,
},
{
  id: 179669899,
  category: "jugos",
  plato: "Jugo",
  name: "Naranja Natural",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: 13000,
},

// inicio cerveza

{
  id: 179669899,
  category: "cerveza",
  plato: "Cerveza",
  name: "Corona",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},
{
  id: 179669899,
  category: "cerveza",
  plato: "Cerveza",
  name: "Club Colombia",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price:"" ,
},

{
  id: 179669899,
  category: "cerveza",
  plato: "Cerveza",
  name: "Aguila Ligth",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
  category: "cerveza",
  plato: "Cerveza",
  name: "Aguila",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price:"" ,
},

{
  id: 179669899,
  category: "cerveza",
  plato: "Cerveza",
  name: "Pilsen",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},
{
  id: 179669899,
  category: "cerveza",
  plato: "Cerveza",
  name: "Tres Cordilleras",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price:"" ,
},

//inicio de cocteleria
  {
    id: 1,
    category: "cocteleria",
    plato: "Coctel",
    name: "Margarita",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 2,
    category: "cocteleria",
    plato: "Coctel",
    name: "Mai Tai",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 3,
    category: "cocteleria",
    plato: "Coctel",
    name: "Escorpión",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 4,
    category: "cocteleria",
    plato: "Coctel",
    name: "Alexander",
  image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
    {
    id: 4,
    category: "cocteleria",
    plato: "Coctel",
    name: "Piña Colada",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 5,
    category: "cocteleria",
    plato: "Coctel",
    name: "Bloody Mary",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 6,
    category: "cocteleria",
    plato: "Coctel",
    name: "Dry Mantini",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 7,
    category: "cocteleria",
    plato: "Coctel",
    name: "Dry Martini Ext",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 8,
    category: "cocteleria",
    plato: "Coctel",
    name: "Escorion",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },
  {
    id: 9,
    category: "cocteleria",
    plato: "Coctel",
    name: "Mai Tai",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 23500,
  },

  {
    id: 9,
    category: "cocteleria",
    plato: "Coctel",
    name: "Mojito",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 22500,
  },
    {
    id: 9,
    category: "cocteleria",
    plato: "Coctel",
    name: "Padrino",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
    desc: "",
    price: 24500,
  },
//fin de cocteleria


{
  id: 179669899,
  category: "licores",
  plato: "Wisky",
  name: "Old Park",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},
{
  id: 179669899,
   category: "licores",
  plato: "Ron Caldas",
  name: "8 años",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "licores",
    plato: "Wisky",
  name: "Chivas",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
  category: "sangrias",
  plato: "Copa Sangria",
  name: "Vino tinto",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
  category: "sangrias",
  plato: "Copa Sangria",
  name: "Vino Rosado",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},
{
  id: 179669899,
  category: "sangrias",
  plato: "Copa Sangria",
  name: "Vino Blanco",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "sangrias",
  plato: "Sangria 1/2",
  name: "Vino Tinto",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "sangrias",
  plato: "Sangria 1/2",
  name: "Vino Rosse",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "sangrias",
  plato: "Sangria 1/2",
  name: "Vino Blanco",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "sangrias",
  plato: "Sangria Jarra",
  name: "Vino Tinto",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "sangrias",
  plato: "Sangria Jarra",
  name: "Vino Blanco",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "sangrias",
  plato: "Sangria Jarra",
  name: "Vino Rosado",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "vinos",
  plato: "Cabernet Suevignon",
  name: "Gato negro",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "vinos",
  plato: "Rosse",
  name: "Gato negro",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},


{
  id: 179669899,
   category: "vinos",
  plato: "Blanco",
  name: "Gato negro",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},

{
  id: 179669899,
   category: "vinos",
  plato: "Cabernet Suevignon",
  name: "Santa Rita",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price:"",
},

{
  id: 179669899,
   category: "vinos",
  plato: "Rosse",
    name: "Santa Rita",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},


{
  id: 179669899,
   category: "vinos",
  plato: "Blanco",
   name: "Santa Rita",
   image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699922683/Asia/xdsvqzwx90gvch2blkm2.png",
  desc: "",
  price: "",
},


{
  id: 60,
  category: "postres",
  plato: "Postres",
  name: "Flan de Coco",
  image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/nd8lfgdywsbekcfgnkzl.png",
  desc: "Disfruta de la suavidad y exquisito sabor tropical con nuestro Flan de Coco.",
  price: 18000,
},
{
  id: 61,
  category: "postres",
  plato: "Postres",
  name: "Flan Caramelo",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/nd8lfgdywsbekcfgnkzl.png",
  desc: "Armonía perfecta entre el dulce toque de caramelo y la textura suave para ti.",
  price: 18000,
},
{
  id: 62,
  category: "postres",
  plato: "Postres",
  name: "Cheese Cake",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/nd8lfgdywsbekcfgnkzl.png",
  desc: "Cheese Cake que te hará sentir como si estuvieras en el cielo de los postres.",
  price: 18000,
},
{
  id: 63,
  category: "postres",
  plato: "Postres",
  name: "Nata",
    image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/nd8lfgdywsbekcfgnkzl.png",
  desc: "Satisface tu antojo de algo dulce y ligero con nuestra Nata.",
  price: 18000,
},
{
  id: 64,
  category: "postres",
  plato: "Postres",
  name: "Tapioca con Mango",
  image: "https://res.cloudinary.com/ddsqxrn0c/image/upload/v1699921567/Asia/nd8lfgdywsbekcfgnkzl.png",
  desc: "Viaja a tierras verdes de china con nuestro Tapioca con Mango.",
  price: 18000,
},
// fin de postres
];

const categorizedProducts = {
  mariscos: products.filter(product => product.category.toLowerCase() === "mariscos"),
  arroz: products.filter(product => product.category.toLowerCase() === "arroz"),
  chop: products.filter(product => product.category.toLowerCase() === "chop"),
  agridulce: products.filter(product => product.category.toLowerCase() === "agridulce"),
  ostras: products.filter(product => product.category.toLowerCase() === "ostras"),
  curry: products.filter(product => product.category.toLowerCase() === "curry"),
  infantil: products.filter(product => product.category.toLowerCase() === "infantil"),
  vegano: products.filter(product => product.category.toLowerCase() === "vegano"),
  vegetariano: products.filter(product => product.category.toLowerCase() === "vegetariano"),
  combinado: products.filter(product => product.category.toLowerCase() === "combinado"),
  tofu: products.filter(product => product.category.toLowerCase() === "tofu"),
  carnes: products.filter(product => product.category.toLowerCase() === "carnes"),
  especiales: products.filter(product => product.category.toLowerCase() === "especiales"),
  sopas: products.filter(product => product.category.toLowerCase() === "sopas"),
  cremas: products.filter(product => product.category.toLowerCase() === "cremas"),
  entradas: products.filter(product => product.category.toLowerCase() === "entradas"),
  bebidas: products.filter(product => product.category.toLowerCase() === "bebidas"),
  postres: products.filter(product => product.category.toLowerCase() === "postres"),
  sangrias: products.filter(product => product.category.toLowerCase() === "sangrias"),
  lomien: products.filter(product => product.category.toLowerCase() === "lomien"),
  vinos: products.filter(product => product.category.toLowerCase() === "vinos"),
  triple: products.filter(product => product.category.toLowerCase() === "triple"),
  cocteleria: products.filter(product => product.category.toLowerCase() === "cocteleria"),
  licores: products.filter(product => product.category.toLowerCase() === "licores"),
  cerveza: products.filter(product => product.category.toLowerCase() === "cerveza"),
  jugos: products.filter(product => product.category.toLowerCase() === "jugos"),
  sodas: products.filter(product => product.category.toLowerCase() === "sodas"),
  hatsu: products.filter(product => product.category.toLowerCase() === "hatsu"),
  gaseosas: products.filter(product => product.category.toLowerCase() === "gaseosas"),
};

export { products, categorizedProducts };
