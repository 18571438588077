export const url = "https://restaurante-asia-c688a236b158.herokuapp.com/";

export const setHeaders = () => {
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };
  
  return headers;
};


