import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, clearCart, decreaseCart, getTotals, removeFromCart,  } from "../slices/cartSlice";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import "./Cart.css";
import { Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';


const Cart = () => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotals());
  }, [cart.cartItems, dispatch]);
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  const handleVolver = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className="cart-container">
        <Typography
          variant="h1"
          sx={{
            marginTop: "0",
            fontSize: "4rem",
            fontWeight: "bold",
            textAlign: "center",
            color: "#D32F2F",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            "@media (max-width: 768px)": {
              fontSize: "2.4rem",
            },
            "@media (max-width: 420px)": {
              fontSize: "2rem",
            },
            "@media (max-width: 390px)": {
              fontSize: "1.4rem",
            },
          }}
        >
          Carrito Virtual Asia
        </Typography>

        {cart.cartItems.length === 0 ? (
          <div className="cart-empty">
            <p>Tu carrito está actualmente vacío</p>
            <div className="start-shopping">
              <Link to="/menu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
                <span>Inicia tu compra</span>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className="titles">
              <h3 className="product-title">Plato</h3>
              <h3 className="price">Precio</h3>
              <h3 className="quantity">Cantidad</h3>
              <h3 className="total">Importe</h3>
            </div>
            <div className="cart-items">
              {cart.cartItems &&
                cart.cartItems.map((cartItem) => (
                  <div className="cart-item" key={cartItem.id}>
                    <div className="cart-product">
                      <img src={cartItem.image} alt={cartItem.name} />
                      <div>
                        <h3><span>{cartItem.plato}</span> {cartItem.name} </h3>
                        <p>{cartItem.desc}</p>
                        <button onClick={() => handleRemoveFromCart(cartItem)}>
                          Remover
                        </button>
                      </div>
                    </div>
                    <div className="cart-product-price">${cartItem.price}</div>
                    <div className="cart-product-quantity">
                      <button onClick={() => handleDecreaseCart(cartItem)}>
                        -
                      </button>
                      <div className="count">{cartItem.cartQuantity}</div>
                      <button onClick={() => handleAddToCart(cartItem)}>+</button>
                    </div>
                    <div className="cart-product-total-price">
                      ${cartItem.price * cartItem.cartQuantity}
                    </div>
                  </div>
                ))}
            </div>
            <div className="cart-summary">
              <button className="clear-btn" onClick={() => handleClearCart()}>
                Vaciar Carrito
              </button>
              <button className="clear-btn" onClick={handleVolver}>Volver</button>
              <div className="cart-checkout">
                <div className="subtotal">
                  <span>Subtotal</span>
                  <span className="amount">${cart.cartTotalAmount}</span>
                </div>
                <p>Envío calculado al finalizar la compra</p>
          
                <Link to={{
                  pathname: "/CheckoutPage",
                  state: {
                    cartItems: cart.cartItems,
                    cartTotalAmount: cart.cartTotalAmount
                  }
                }}>
                  <button>

Continuar

                  </button>
                </Link>
                <div className="continue-shopping">
                  <Link to="/menu">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>
                    <span>Sigue comprando</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Cart;
