import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import "./CheckoutPage.css";
const CheckoutPage = () => {
  const { cartTotalQuantity, cartTotalAmount, cartItems } = useSelector((state) => state.cart);
const [isChecked, setIsChecked] = useState(false);
  // Estados para campos de entrada
  const [nombre, setNombre] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');
  const [correo, setCorreo] = useState('');
  // Estado para el costo de domicilio
  const [selectedBarrio, setSelectedBarrio] = useState('');
  const [costoDomicilio, setCostoDomicilio] = useState(0);
  // Estado para el medio de pago
  const [selectedPay, setSelectedPay] = useState('');
  const [pagoPor, setPagoPor] = useState('No definido');
  // Manejador de cambio de barrio
  const handleBarrioChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedBarrio(selectedOption);
    // Asigna el costo correspondiente al barrio seleccionado
    switch (selectedOption) {
      case 'Poblado':
        setCostoDomicilio(2000);
        break;
      case 'Laureles':
        setCostoDomicilio(4000);
        break;
      case 'Envigado':
        setCostoDomicilio(5000);
        break;
      case 'Sabaneta':
        setCostoDomicilio(5000);
        break;
      case 'Itagüí':
        setCostoDomicilio(7000);
        break;
      case 'Centro':
        setCostoDomicilio(4000);
        break;
      case 'Buenos Aires':
        setCostoDomicilio(4000);
        break;
      case 'Robledo':
        setCostoDomicilio(6000);
        break;
      case 'Bello':
        setCostoDomicilio(8000);
        break;
      case 'indefinido':
        setCostoDomicilio(0);
        break;
      case 'recoger':
        setCostoDomicilio(0);
        break;
      default:
        setCostoDomicilio(0);
    }
  };
  // Manejador de cambio de medio de pago
  const handlePayChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedPay(selectedOption);

    // Asigna el medio de pago seleccionado
    switch (selectedOption) {
      case 'efectivo':
        setPagoPor('Efectivo');
        break;
      case 'tarjeta':
        setPagoPor('Tarjeta');
        break;
      default:
        setPagoPor('No definido');
    }
  };
 const handleClick = () => {
    if (isChecked) {
      const phoneNumber = "573044936299"; // Número de teléfono de destino
      const pedidoDetallado = cartItems.map((cartItem) => (
        `Plato: ${cartItem.plato}, Nombre: ${cartItem.name}, Valor: ${cartItem.price}, Cantidad: ${cartItem.cartQuantity}`
      )).join('\n');

      const pedido = `Nuevo pedido:\nNombre: ${nombre}\nTeléfono: ${telefono}\nDirección: ${direccion}\nCorreo: ${correo}\nDetalles del Pedido:\n${pedidoDetallado}\nTotal Items: ${cartTotalQuantity}\nBarrio: ${selectedBarrio}\nDomicilio: $${costoDomicilio}\nTotal: $${cartTotalAmount + costoDomicilio}\nMedio de pago: ${pagoPor}`;

      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(pedido)}`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      console.log('Debes aceptar el manejo de datos personales');
    }
  };
  return (
    <Layout>
      <div className="cart-container">
        <Typography
          variant="h1"
          sx={{
            marginTop: "0",
            fontSize: "6rem",
            fontWeight: "bold",
            textAlign: "center",
            color: "#D32F2F",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            "@media (max-width: 768px)": {
              fontSize: "3.6rem",
            },
            "@media (max-width: 420px)": {
              fontSize: "3rem",
            },
            "@media (max-width: 390px)": {
              fontSize: "2.4rem",
            },
          }}
        >
        Información
        </Typography>
        <div className="checkout-summary">
          <div>
            <h3 className="domicilios-delivery-title">Para</h3>
            <h2 className="domicilios-delivery-title">Domicilio o Recoger</h2>
       
            <div>
              <label htmlFor="barrioSelector">Selecciona tu Barrio: </label>
              <select id="barrioSelector" value={selectedBarrio} onChange={handleBarrioChange}>
                <option value="">Barrio:</option>
                <option value="Poblado">Poblado</option>
                <option value="Laureles">Laureles</option>
                <option value="Envigado">Envigado</option>
                <option value="Sabaneta">Sabaneta</option>
                <option value="Itagüí">Itagüí</option>
                <option value="indefinido">Indefinido</option>
                <option value="Centro">Centro</option>
                <option value="Buenos Aires">Buenos Aires</option>
                <option value="Robledo">Robledo</option>
                <option value="Bello">Bello</option>
                <option value="recoger">RECOGER</option>
              </select>
            </div>
            <div>
              <label htmlFor="paySelector">Selecciona tu Medio de Pago: </label>
              <select id="paySelector" value={selectedPay} onChange={handlePayChange}>
                <option value="">Pago:</option>
                <option value="efectivo">Efectivo</option>
                <option value="tarjeta">Tarjeta</option>
              </select>
            </div>
            <div>
              <label htmlFor="nombreInput">Nombre: </label>
              <input type="text" id="nombreInput" value={nombre} onChange={(e) => setNombre(e.target.value)} />
            </div>
            <div>
              <label htmlFor="telefonoInput">Teléfono: </label>
              <input type="text" id="telefonoInput" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
            </div>
            <div>
              <label htmlFor="direccionInput">Dirección: </label>
              <input type="text" id="direccionInput" value={direccion} onChange={(e) => setDireccion(e.target.value)} />
            </div>
            <div>
              <label htmlFor="correoInput">Correo Electrónico: </label>
              <input type="text" id="correoInput" value={correo} onChange={(e) => setCorreo(e.target.value)} />
            </div>
            <div className="delivery-summary">
 {cartItems.map((cartItem) => (
    <div key={cartItem.id}>
      <p className="item">Plato: {cartItem.plato} <span>{cartItem.name}</span></p>
      <p className="item">Valor: <span>{cartItem.price}</span>$</p>
    </div>
  ))}
              <p className="item">Cantidad de platos : <span>{cartTotalQuantity}</span></p>
              <p className="item">Subtotal : <span>{cartTotalAmount}$</span></p>
              <p className="item">Barrio seleccionado :<span>{selectedBarrio}</span></p>
              <p className="item">Costo de domicilio : <span>{costoDomicilio}</span>$. El costo del domicilio es un aproximado y puede variar con relación a la distancia.</p>
              <p className="item">Total :<span>{cartTotalAmount + costoDomicilio}$</span></p>
              <p className="item">Medio de pago :<span>{pagoPor}</span></p>
            </div>
          </div>
        </div>
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <br />
      <button
        style={{
          padding: '14px 28px',
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
        }}
        onClick={handleClick}
        disabled={!isChecked}
      >
        Pedido por Whatsapp
      </button>

      <br />
        <label style={{ display: 'block', marginBottom: '10px' }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        Confirmo datos para envio a domicilio / recoger.
      </label>
    
      <button
        style={{
          marginTop: '44px',
          padding: '2px 4px',
          fontSize: '11px',
          cursor: 'pointer',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
        }}
        onClick={() => window.location.href = "https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981#:~:text=La%20presente%20ley%20tiene%20por,el%20art%C3%ADculo%2015%20de%20la"}
      >
       Ley estatutaria 1581 de 2012, véala aquí
      </button>
    </div>
      </div>
    </Layout>
  );
};
export default CheckoutPage;
