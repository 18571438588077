import React, { useEffect, useState } from 'react';
import './Section.css'; // Importa el archivo CSS
import { Link } from 'react-router-dom';

// Define las rutas de las imágenes correctamente
import uno from '../images/Chow-Fan.webp'; // Ruta de la imagen uno
import dos from '../images/chopsuey.webp'; // Ruta de la imagen dos
import tres from '../images/salsaagriculce.webp'; // Ruta de la imagen tres
import cuatro from '../images/Entradas.webp'; // Ruta de la imagen cuatro

const Section = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', checkMobile);
    checkMobile();

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const items = [
    {
      title: 'Arroz Chino',
      imageSrc: uno,
      description: 'Arroz frito con cebolla, raíces de mogo.',
      linkTo: '/ArrozChino',
    },
    {
      title: 'ChopSuey',
      imageSrc: dos,
      description: 'Con Res, Pollo, Camarones o Langostinos.',
      linkTo: '/ChopSuey',
    },
    {
      title: 'Agridulce',
      imageSrc: tres,
      description: 'Pollo, Cerdo, Costillas y Pescado.',
      linkTo: '/SalsaAgridulce',
    },
    {
      title: 'Lumpias',
      imageSrc: cuatro,
      description: 'Entradas con salsa agridulce y más.',
      linkTo: '/Entradas',
    },
  ];

  return (
    <div className={`section ${isMobile ? 'mobile' : ''}`}>
      <div className="section-container">
        {items.map((item, index) => (
          <div
            key={index}
            className={`section-item ${isMobile ? 'mobile' : ''}`}
          >
            <Link to={item.linkTo}>
              <div className="image-container">
                <img
                  src={item.imageSrc}
                  alt={item.title}
                  className={`image ${isMobile ? 'mobile' : ''}`}
                  loading="lazy"
                />
                <div className={`hover-text ${isMobile ? 'mobile' : ''}`}>
                  <h3 className={`title ${isMobile ? 'mobile' : ''}`}>
                    {item.title}
                  </h3>
                  <p>{item.description}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section;


