import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import Pagenotfound from "./pages/Pagenotfound";
import ChopSuey from "./pages/ChopSuey";
import Bebidas from "./pages/Bebidas";
import Carnes from "./pages/Carnes";
import ArrozChino from "./pages/ArrozChino";
import Entradas from "./pages/Entradas";
import Mariscos from "./pages/Mariscos";
import Menuinfantil from "./pages/Menuinfantil";
import PlatoCombinado from "./pages/PlatoCombinado";
import PlatoTriple from "./pages/PlatoTriple";
import PlatosEspeciales from "./pages/PlatosEspeciales";
import Postres from "./pages/Postres";
import SalsaAgridulce from "./pages/SalsaAgridulce";
import SalsaCurry from "./pages/SalsaCurry";
import SalsaOstras from "./pages/SalsaOstras";
import Sopas from "./pages/Sopas";
import ToFu from "./pages/ToFu";
import Vegetariano from "./pages/Vegetariano";
import Vegano from "./pages/Vegano";
import Lomien from "./pages/Lomien";
import CartaChino from "./pages/CartaChino";
import Carta from "./pages/Carta";
import CartaIngles from "./pages/CartaIngles";
import MiniFooter from './components/Layout/MiniFooter';
import Salon from "./pages/Salon";
import Cart from "./pages/Cart";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Cremas from "./pages/Cremas";
import CheckoutPage from "./pages/CheckoutPage";
import Cerveza from "./pages/Bebidas/Cerveza";
import Cocteleria from "./pages/Bebidas/Cocteleria";
import Gaseosas from "./pages/Bebidas/Gaseosas";
import Hatsu from "./pages/Bebidas/Hatsu";
import Jugos from "./pages/Bebidas/Jugos";
import Licores from "./pages/Bebidas/Licores";
import Sangrias from "./pages/Bebidas/Sangrias";
import Sodas from "./pages/Bebidas/Sodas";
import Vinos from "./pages/Bebidas/Vinos";
function App() {
  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
<Route path="/CheckoutPage" element={<CheckoutPage/>} />
          <Route path="/Salon" element={<Salon />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Cremas" element={<Cremas />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/ChopSuey" element={<ChopSuey />} />
          <Route path="/Bebidas" element={<Bebidas />} />
          <Route path="/Carnes" element={<Carnes />} />
          <Route path="/ArrozChino" element={<ArrozChino />} />
          <Route path="/Entradas" element={<Entradas />} />
          <Route path="/Mariscos" element={<Mariscos />} />
          <Route path="/Menuinfantil" element={<Menuinfantil />} />
          <Route path="/PlatoCombinado" element={<PlatoCombinado />} />
          <Route path="/PlatoTriple" element={<PlatoTriple />} />
          <Route path="/PlatosEspeciales" element={<PlatosEspeciales />} />
          <Route path="/Postres" element={<Postres />} />
             <Route path="/Cerveza" element={<Cerveza />} />
                <Route path="/Cocteleria" element={<Cocteleria />} />
                   <Route path="/Gaseosas" element={<Gaseosas />} />
                      <Route path="/Hatsu" element={<Hatsu />} />
                         <Route path="/Jugos" element={<Jugos />} />
                            <Route path="/Licores" element={<Licores />} />
                               <Route path="/Sangrias" element={<Sangrias />} />
                                  <Route path="/Sodas" element={<Sodas />} />
                                       <Route path="/Vinos" element={<Vinos />} />
          <Route path="/SalsaAgridulce" element={<SalsaAgridulce />} />
          <Route path="/SalsaCurry" element={<SalsaCurry />} />
          <Route path="/SalsaOstras" element={<SalsaOstras />} />
          <Route path="/Sopas" element={<Sopas />} />
          <Route path="/ToFu" element={<ToFu />} />
          <Route path="/Vegetariano" element={<Vegetariano />} />
          <Route path="/Vegano" element={<Vegano />} />
          <Route path="/CartaChino" element={<CartaChino />} />
          <Route path="/CartaIngles" element={<CartaIngles />} />
          <Route path="/Carta" element={<Carta />} />
          <Route path="/Lomien" exact element={<Lomien />} />
          <Route path="/Cart" element={<Cart /> } />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
        <MiniFooter />
      </BrowserRouter>

    </div>
  );
}
export default App;


























