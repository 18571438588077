import React from "react";
import Layout from "./../components/Layout/Layout";
import "../styles/menu_cuadros.css";
import exportObject from "../data/data"; // Importa los datos directamente desde data

function CartaIngles() {
  // Desestructura las variables importadas
  const { Cartausa } = exportObject;

  // Estilos para el título creativo
  const tituloCreativoStyles = {
    fontSize: "2rem", // Tamaño de fuente
    fontWeight: "bold", // Peso de fuente
    textAlign: "center", // Alineación de texto
    color: "#333", // Color de texto principal
    marginBottom: "20px", // Margen inferior
  };

  // Estilos para la bienvenida en inglés
  const bienvenidaInglesStyles = {
    fontSize: "1.5rem", // Tamaño de fuente
    color: "#666", // Color de texto secundario
    marginBottom: "20px", // Margen inferior
    marginLeft: "10px", // Margen izquierdo
    marginRight: "10px", // Margen derecho
    textAlign: "center", // Alineación de texto
  };

  return (
    <Layout>
      <main>
        <div className="contenedor_menu">
          {/* Título creativo */}
          <h1 style={tituloCreativoStyles}>Welcome to the English Menu!</h1>
          {/* Bienvenida en inglés */}
          <p style={bienvenidaInglesStyles}>Explore our delicious dishes from around the world.</p>
        </div>
        {Cartausa.categories.map((categoria, index) => (
          <div className="contenedor_menu" key={index} id={categoria.name}>
            <h2 className="titulo_seccion">{categoria.name}</h2>
            <div className="seccion_menu">
              {categoria.items.map((item, itemIndex) => (
                <div className="item_menu" key={itemIndex}>
                  <p className="nombre_plato">{item.dish_name}</p>
                  <p className="descripcion_plato">{item.dish_description}</p>
                  <p className="precio_plato">
                    <span className="puntos">..................</span>
                    {item.dish_price}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </main>
    </Layout>
  );
}

export default CartaIngles;
