import Entradas from "../images/Entradas.webp";
import Sopa from "../images/Wontons.webp";
import Especial from "../images/PlatoEspecial.webp";
import ChowFan from "../images/Chow-Fan.webp";
import SalsaAgridulce from "../images/salsaagriculce.webp";
import SalsaCurry from "../images/salsadecurry.webp";
import SalsaOstras from "../images/salsadeostras.webp";
import ChopSuey from "../images/chopsuey.webp";
import Vegetariano from "../images/TofuA.webp";
import Vegano from "../images/Vegano.webp";
import Mariscos from "../images/mariscos.webp";
import PlatoCombinado from "../images/ChowFan.webp";
import ToFu from "../images/ToFu.webp";
import Carnes from "../images/milanesa.webp";
import Bebidas from "../images/sodasandia.webp";
import Postres from "../images/6.webp";
import Infa from "../images/menuInfantil.webp";
import mien from "../images/lomien.webp";
import cremas from "../images/crema.webp";
import CervezaImage from "../img/cerveza.webp";
import CocteleriaImage from "../img/margarita.webp";
import GaseosasImage from "../img/gaseosa.webp";
import HatsuImage from "../img/7707362390079-30.webp";
import JugosImage from "../img/mandarina.webp";
import LicoresImage from "../img/whiskyoldparr.webp";
import SangriasImage from "../img/images.svg";
import SodasImage from "../img/lychee.webp";
import VinosImage from "../img/120.webp";
import PlatoTriple from "../img/china.webp";


export const MenuList = [
  {
    "name": "Entradas",
    "description": "Costilla asada, lumpias y wantons fritos.",
    "image": Entradas,
    "gelemento": "Ver Entradas",
    "link": "/Entradas",
  },
  {
    "name": "Sopas",
    "description": "El menú de sopas de Asia.",
    "image": Sopa,
    "gelemento": "Ver Sopas",
    "link": "/Sopas",
  },
    {
    "name": "Cremas",
    "description": "El menú de cremas de Asia.",
    "image": cremas,
    "gelemento": "Ver cremas",
    "link": "/Cremas",
  },
  {
    "name": "Platos de Especialidad",
    "description": "Opciones para diversos gustos.",
    "image": Especial,
    "gelemento": "Ver Especial",
    "link": "/PlatosEspeciales",
  },
  {
    "name": "Arroz Chino",
    "description": "Arroz frito originario de China.",
    "image": ChowFan,
    "gelemento": "Ver Arroces",
    "link": "/ArrozChino",
  },
  {
    "name": "Salsa Agridulce",
    "description": "Son especialidades chinas.",
    "image": SalsaAgridulce,
    "gelemento": "Ver Agridulces",
    "link": "/SalsaAgridulce",
  },
  {
    "name": "Salsa Curry",
    "description": "Mezcla de especias y hierbas.",
    "image": SalsaCurry,
    "gelemento": "Ver Currys",
    "link": "/SalsaCurry",
  },
  {
    "name": "Salsa Ostras",
    "description": "Salsa oscura hecha a base de ostras.",
    "image": SalsaOstras,
    "gelemento": "Ver Ostras",
    "link": "/SalsaOstras",
  },
  {
    "name": "Chop Suey",
    "description": "Plato de vegetales y carne.",
    "image": ChopSuey,
    "gelemento": "Ver Chop Sueys",
    "link": "/ChopSuey",
  },
  {
    "name": "Vegetariano",
    "description": "Rica comida china sin carne.",
    "image": Vegetariano,
    "gelemento": "Ver Vegetarianos",
    "link": "/Vegetariano",
  },
  {
    "name": "Vegano",
    "description": "Rica comida china sin carne.",
    "image": Vegano,
    "gelemento": "Ver Veganos",
    "link": "/Vegano",
  },
  {
    "name": "Platos de Mariscos",
    "description": "Camarones, langostinos, pescado y más.",
    "image": Mariscos,
    "gelemento": "Ver Mariscos",
    "link": "/Mariscos#mariscos",
  },
  {
    "name": "Platos Combinados",
    "description": "Los platos combinados de Asia.",
    "image": PlatoCombinado,
    "gelemento": "Ver Combinados",
    "link": "/PlatoCombinado",
  },
    {
    "name": "Platos Combinado Triple",
    "description": "Plato combinado Triple.",
    "image": PlatoTriple,
    "gelemento": "Ver Triples",
    "link": "/PlatoTriple",
  },
  {
    "name": "Lo mien",
    "description": "Pasta China .",
    "image": mien,
    "gelemento": "Ver Lomien",
    "link": "/Lomien",
  }, 
  {
    "name": "Tofu",
    "description": "Hecho a partir de la leche de soja.",
    "image": ToFu,
    "gelemento": "Ver Tofus",
    "link": "/Tofu",
  },
  {
    "name": "Infantil",
    "description": "Comida china para niños",
    "image": Infa,
    "gelemento": "Ver Infantil",
    "link": "/Menuinfantil",
  },
  {
    "name": "Carnes",
    "description": "Carne asada, filete mignon y cerdo milanesa.",
    "image": Carnes,
    "gelemento": "Ver Carnes",
    "link": "/Carnes",
  },
  {
    "name": "Bebidas",
    "description": "Gaseosas, té HATSU y sodas.",
    "image": Bebidas,
    "gelemento": "Ver Bebidas",
    "link": "/Bebidas",
  },
  {
    "name": "Postres",
    "description": "Tapioca, flan caramelo, cheesecake.",
    "image": Postres,
    "gelemento": "Ver Postres",
    "link": "/Postres",
  },
];


export const BebiList = [
  {
    "name": "Cerveza",
    "description": "Cerveza para acompañar tus platillos.",
    "image": CervezaImage,
    "gelemento": "Ver Cervezas",
    "link": "/Cerveza",
  },
  {
    "name": "Cocteleria",
    "description": "Variedad de cócteles para disfrutar.",
    "image": CocteleriaImage,
    "gelemento": "Ver Cocteles",
    "link": "/Cocteleria",
  },
  {
    "name": "Gaseosas",
    "description": "Refrescantes bebidas gaseosas.",
    "image": GaseosasImage,
    "gelemento": "Ver Gaseosas",
    "link": "/Gaseosas",
  },
  // Agrega las otras bebidas aquí siguiendo el mismo patrón
  {
    "name": "Hatsu",
    "description": "Descripción de Hatsu.",
    "image": HatsuImage,
    "gelemento": "Ver Hatsu",
    "link": "/Hatsu",
  },
  {
    "name": "Jugos",
    "description": "Descripción de Jugos.",
    "image": JugosImage,
    "gelemento": "Ver Jugos",
    "link": "/Jugos",
  },
  {
    "name": "Licores",
    "description": "Descripción de Licores.",
    "image": LicoresImage,
    "gelemento": "Ver Licores",
    "link": "/Licores",
  },
  {
    "name": "Sangrias",
    "description": "Descripción de Sangrias.",
    "image": SangriasImage,
    "gelemento": "Ver Sangrias",
    "link": "/Sangrias",
  },
  {
    "name": "Sodas",
    "description": "Descripción de Sodas.",
    "image": SodasImage,
    "gelemento": "Ver Sodas",
    "link": "/Sodas",
  },
  {
    "name": "Vinos",
    "description": "Descripción de Vinos.",
    "image": VinosImage,
    "gelemento": "Ver Vinos",
    "link": "/Vinos",
  },
];




export const Cartaespañol = {
  "categorias": [
   {
      "nombre": "Bebidas Refrescantes",
      "items": [
        {
          "nombre_plato": "Té Hatsu",
          "descripcion_plato": "Verde, Rosado, Negro, Amarillo, Blanco, Lila.",
          "precio_plato": "$11.000"
        },
        {
          "nombre_plato": "Cocacola",
          "descripcion_plato": "Zero, Cuatro, Cocacola, Sprite.",
          "precio_plato": "$9.500"
        },
        {
          "nombre_plato": "Postobón",
          "descripcion_plato": "Manzana, 7up, Colombiana.",
          "precio_plato": "$9.500"
        },
        {
          "nombre_plato": "Sodas Saborizadas",
          "descripcion_plato": "Sandia, Maracuya, Lichys.",
          "precio_plato": "$14.900"
        }
      ]
    },

    {
      "nombre": "Entradas",
      "items": [
        {
          "nombre_plato": "Lumpia",
          "descripcion_plato": "Rollo frito de carne de cerdo y vegetales con salsa agridulce..",
          "precio_plato": "$9.500"
        },
        {
          "nombre_plato": "Costilla Asada",
          "descripcion_plato": "Jugosas costillas asadas con salsa barbacoa.",
          "precio_plato": "$53.000"
        },
        {
          "nombre_plato": "Wan Tum Frito",
          "descripcion_plato": "Ojuelas fritas (no vegetales ni carne).",
          "precio_plato": "$26.500"
        },
        {
          "nombre_plato": "Copa de Langostinos",
          "descripcion_plato": "Copa con deliciosos Langostinos en salsa rosada.",
          "precio_plato": "$68.000"
        },
        {
          "nombre_plato": "Copa de Camarón",
          "descripcion_plato": "Copa con Camarones frescos.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Combinación de la Casa",
          "descripcion_plato": "Plato variado de carnes, pollo, cerdo, langostinos, lumpias, costillas y wanton Frito.",
          "precio_plato": "$68.000"
        },
        {
          "nombre_plato": "Copa de Huevo de Codorniz",
          "descripcion_plato": "Huevos de codorniz en salsa rosada.",
          "precio_plato": "$24.000"
        }
      ]
    },
    {
      "nombre": "Sopas",
      "items": [
        {
          "nombre_plato": "Hot and Sour",
          "descripcion_plato": "Sopa de pequeños trozos de carne de cerdo y Tofu, picante.",
          "precio_plato": "$22.500"
        },
        {
          "nombre_plato": "Wanton",
          "descripcion_plato": "Sopa asiática encantadora, con salsa de soja al gusto.",
          "precio_plato": "$22.500"
        },
        {
          "nombre_plato": "Yat Ko Mien",
          "descripcion_plato": "Sopa de cerdo jamón con fideos y huevo.",
          "precio_plato": "$22.500"
        },
         {
          "nombre_plato": "Sopa de Mariscos",
          "descripcion_plato": "Sopa china",
          "precio_plato": "$25.500"
        },
        {
          "nombre_plato": "Crema de Camarones",
          "descripcion_plato": "Crema con trocitos de Camarones.",
          "precio_plato": "$24.000"
        },
        {
          "nombre_plato": "Crema de Langostino",
          "descripcion_plato": "Crema con trocitos de Langostinos.",
          "precio_plato": "$26.500"
        },
        {
          "nombre_plato": "Crema de Cebolla",
          "descripcion_plato": "Crema con trocitos de Cebolla.",
          "precio_plato": "$20.500"
        },
        {
          "nombre_plato": "Crema de Brócoli",
          "descripcion_plato": "Crema con trocitos de Brócoli.",
          "precio_plato": "$20.500"
        },
        {
          "nombre_plato": "Crema de Pollo",
          "descripcion_plato": "Crema con trocitos de Pollo.",
          "precio_plato": "$22.500"
        },
        {
          "nombre_plato": "Crema de Pescado",
          "descripcion_plato": "Crema con trocitos de Pescado.",
          "precio_plato": "$22.500"
        }
      ]
    },
    {
      "nombre": "Platos Especiales",
      "items": [
        {
          "nombre_plato": "Pollo en Salsa de Naranja",
          "descripcion_plato": "Delicioso corte de pollo apanado bañado en salsa de naranja.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Chaw Pat Chin",
          "descripcion_plato": "Langostino, ToFu, Pollo, Cerdo, Jamón  y variedad de Vegetales.",
          "precio_plato": "$60.000"
        },
        {
          "nombre_plato": "Brócoli Pollo",
          "descripcion_plato": "Pollo bañado en salsa de mariscos, sobre una cama de Brócoli.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Brócoli Res",
          "descripcion_plato": "Res bañada en salsa de mariscos, sobre una cama de brócoli.",
          "precio_plato": "$61.000"
        },
        {
          "nombre_plato": "Brócoli Langostino",
          "descripcion_plato": "Langostino salteado en salsa de mariscos sobre una cama de Brócoli.",
          "precio_plato": "$74.000"
        },
        {
          "nombre_plato": "Pollo en Ajonjolí en salsa de Mariscos",
          "descripcion_plato": "Pollo en salsa de mariscos y ajonjolí.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Langostinos Salteados",
          "descripcion_plato": "Langostinos con aliños y sal.",
          "precio_plato": "$74.000"
        },
        {
          "nombre_plato": "Res con jengibre en salsa de ostras",
          "descripcion_plato": "Deliciosos trozos de res en salsa ostras.",
          "precio_plato": "$60.000"
        },
        {
          "nombre_plato": "Camarones con papa al vapor al ajillo",
          "descripcion_plato": "Camarones al ajillo con papa al vapor y mantequilla.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Tocino Jalapeño",
          "descripcion_plato": "Tocino finamente picado en Jalapeños.",
          "precio_plato": "$61.000"
        },
         {
          "nombre_plato": "Cazuela de Res",
          "descripcion_plato": "Cazuela de res picante con vegetales chinos; una experiencia auténtica para los amantes del picante.",
          "precio_plato": "$99.500"
        }
      ]
    },
    {
      "nombre": "Arroz Chino",
      "items": [
        {
          "nombre_plato": "Arroz Pollo",
          "descripcion_plato": "Arroz con ricos trozos de pollo, raíces y cebollín",
          "precio_plato": "$55.000"
        },
        {
          "nombre_plato": "Arroz Res",
          "descripcion_plato": "Arroz con carne de res, raíces y cebollín .",
          "precio_plato": "$58.000"
        },
        {
          "nombre_plato": "Arroz Langostinos",
          "descripcion_plato": "Arroz con ricos langostinos, raíces y cebollín.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Arroz Mariscos",
          "descripcion_plato": "Arroz chino con deliciosos mariscos, pescado, camarón y langostino.",
          "precio_plato": "$65.000"
        },
        {
          "nombre_plato": "Arroz Camarones",
          "descripcion_plato": "Arroz frito con camarones.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Arroz 8 Sabores",
          "descripcion_plato": "Arroz salteado con Langostinos, Res, Pollo, Camarones, Cerdo,  y Jamón.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Arroz Especial",
          "descripcion_plato": "Arroz chino especial con Pollo, Cerdo, Jamon y huevo.",
          "precio_plato": "$53.000"
        },
        {
          "nombre_plato": "Medio Arroz Especial",
          "descripcion_plato": "Arroz frito pequeño con Pollo, Cerdo, Jamon.",
          "precio_plato": "$37.000"
        },
        {
          "nombre_plato": "Medio Arroz Especial con Camarones",
          "descripcion_plato": "Arroz chino especial con Pollo, Cerdo, Jamon + Camarones.",
          "precio_plato": "$44.000"
        }
      ]
    },



    {
      "nombre": "Salsa Agridulce",
      "items": [
        {
          "nombre_plato": "Salsa agridulce con Pollo",
          "descripcion_plato": "Croquetas de tierno pollo, bañadas en deliciosa salsa agridulce.",
          "precio_plato": "$55.000"
        },
        {
          "nombre_plato": "Salsa agridulce con Cerdo",
          "descripcion_plato": "Croquetas de cerdo seleccionado, bañadas en deliciosa salsa agridulce.",
          "precio_plato": "$53.000"
        },
        {
          "nombre_plato": "Salsa agridulce con Langostinos",
          "descripcion_plato": "Croquetas de deliciosos langostinos, bañadas en salsa agridulce.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Salsa agridulce con Pescado",
          "descripcion_plato": "Croquetas de pescado fresco, bañadas en salsa agridulce.",
          "precio_plato": "$55.000"
        },
        {
          "nombre_plato": "Salsa agridulce con Costilla",
          "descripcion_plato": "Croquetas de costillas, bañadas en salsa agridulce.",
          "precio_plato": "$54.000"
        },
        {
          "nombre_plato": "Salsa agridulce pequeña con Pollo",
          "descripcion_plato": "Salsa agridulce con delicioso pollo.",
          "precio_plato": "$38.500"
        },
        {
          "nombre_plato": "Salsa agridulce pequeña con Cerdo",
          "descripcion_plato": "Salsa agridulce con delicioso Cerdo.",
          "precio_plato": "$38.500"
        }
      ]
    },
    {
      "nombre": "Salsa Curry",
      "items": [
        {
          "nombre_plato": "Pollo con salsa de Curry",
          "descripcion_plato": "Pollo con salsa de curry papa vapor y cebolla.",
          "precio_plato": "$56.000"
        },
        {
          "nombre_plato": "Langostinos con salsa de Curry",
          "descripcion_plato": "Langostinos con salsa de curry papa vapor y cebolla.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Pescado con salsa de Curry",
          "descripcion_plato": "Pescado con salsa de curry papa vapor y cebolla.",
          "precio_plato": "$59.000"
        },
      ]
    },
    {
      "nombre": "Salsa de Ostras",
      "items": [
        {
          "nombre_plato": "Pollo con salsa de Ostras",
          "descripcion_plato": "Pollo con salsa de ostras raíces chinas y cebolla.",
          "precio_plato": "$57.000"
        },
        {
          "nombre_plato": "Carne de res con salsa de Ostras",
          "descripcion_plato": "Carne de res con salsa de ostras raíces chinas y cebolla.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Langostinos con salsa de Ostras",
          "descripcion_plato": "Langostinos con salsa de ostras raíces chinas y cebolla.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Pescado con salsa de Ostras",
          "descripcion_plato": "Pescado con salsa de ostras raíces chinas y cebolla.",
          "precio_plato": "$59.000"
        },
      ]
    },
    {
      "nombre": "Chopsuey",
      "items": [
        {
          "nombre_plato": "Chop Suey con Pollo",
          "descripcion_plato": "Pollo y vegetales en salsa soya.",
          "precio_plato": "$50.000"
        },
        {
          "nombre_plato": "Chop Suey con Carne de Res",
          "descripcion_plato": "Res y vegetales en salsa soya.",
          "precio_plato": "$58.000"
        },
        {
          "nombre_plato": "Chop Suey con Langostinos",
          "descripcion_plato": "Langostinos y vegetales en salsa soya.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Chop Suey con Mariscos",
          "descripcion_plato": "Mariscos como pescado, camarones y langostinos con vegetales en salsa soya.",
          "precio_plato": "$65.000"
        },
        {
          "nombre_plato": "Chop Suey con Camarones",
          "descripcion_plato": "Camarones y vegetales en salsa soya.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Chop Suey Especial",
          "descripcion_plato": "Chop Suey Especial con Pollo, Cerdo, Jamon y vegetales en salsa soya.",
          "precio_plato": "$53.000"
        },
        {
          "nombre_plato": "Chop Suey pequeño con Pollo, Cerdo, Jamon",
          "descripcion_plato": "Chop Suey pequeño con Pollo, Cerdo, Jamon y vegetales en salsa soya.",
          "precio_plato": "$37.000"
        },
        {
          "nombre_plato": "Chop Suey Especial pequeño + Camarones",
          "descripcion_plato": "Chop Suey Especial con Pollo, Cerdo, Jamon + Camarones y vegetales en salsa soya.",
          "precio_plato": "$44.000"
        }
      ]
    },
    {
      "nombre": "Platos Vegetarianos",
      "items": [
        {
          "nombre_plato": "Lomien sin Carne",
          "precio_plato": "$46.000"
        },
        {
          "nombre_plato": "Arroz Frito con Raíces y Huevo",
          "precio_plato": "$46.000"
        },
        {
          "nombre_plato": "Vegetales en Salsa de Soya",
          "precio_plato": "$46.000"
        },
        {
          "nombre_plato": "Tofu en Salsa Agridulce",
          "precio_plato": "$46.000"
        }
      ]
    },
    {
      "nombre": "Mariscos",
      "items": [
        {
          "nombre_plato": "Langostino a la Mariposa",
          "descripcion_plato": "Deliciosos langostinos preparados a la mariposa con tocineta y papas a la francesa.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Langostino a la Plancha",
          "descripcion_plato": "Langostinos frescos a la plancha con un toque asiático y papas a la francesa.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Langostino Ajillo",
          "descripcion_plato": "Langostinos salteados al ajillo y papa al vapor.",
          "precio_plato": "$72.000"
        },
        {
          "nombre_plato": "Cazuela de Mariscos",
          "descripcion_plato": "Exquisita cazuela con una variedad de mariscos frescos como pescado, langostinos y camarones en salsa roja de la casa.",
          "precio_plato": "$70.000"
        },
        {
          "nombre_plato": "Pescado con Vegetales en Salsa de Soya",
          "descripcion_plato": "Filete de pescado fresco acompañado de vegetales salteados en salsa de soya.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Pescado a la Milanesa",
          "descripcion_plato": "Pescado empanizado y dorado a la perfección, acompañado de papas fritas y ensalada.",
          "precio_plato": "$59.000"
        },
        {
          "nombre_plato": "Pescado Plancha",
          "descripcion_plato": "Filete de pescado a la plancha con papas fritas y ensalada.",
          "precio_plato": "$59.000"
        }
      ]
    },
    {
      "nombre": "Platos Combinados",
      "items": [
        {
          "nombre_plato": "Arroz Especial y Chop Suey",
            "descripcion_plato": "Arroz frito con carne de Cerdo, Pollo y Jamón más Vegetales Chinos",
            "precio_plato": "$48.000"
        
        },
        {
          "nombre_plato": "Chop Suey y Cerdo en Salsa Agridulce",
          "descripcion_plato": "Vegetales y Croquetas de Cerdo Agridulce.",
          "precio_plato": "$48.000"
        },
        {
          "nombre_plato": "Arroz Especial y Cerdo en Salsa Agridulce",
          "descripcion_plato": "Arroz frito con carne de cerdo, pollo y jamón y croquetas de Cerdo Agridulce.",
          "precio_plato": "$49.000"
        },
        {
          "nombre_plato": "Arroz Especial y Pollo en Salsa Agridulce",
          "descripcion_plato": "Arroz frito con carne de cerdo, pollo y jamón y croquetas de Pollo Agridulce.",
          "precio_plato": "$49.000"
        },
      ]
    },
     {
      "nombre": "Platos Combinados Triple",
      "items": [
        {
          "nombre_plato": "Triple de Pollo",
          "descripcion_plato": "Chop Suey + Arroz frito con carne de cerdo, pollo y jamón y croquetas de Pollo Agridulce.",
          "precio_plato": "$50.000"
        },
        {
          "nombre_plato": "Triple de Cerdo",
          "descripcion_plato": "Chop Suey + Arroz frito con carne de cerdo, pollo y jamón y croquetas de Cerdo Agridulce.",
          "precio_plato": "$49.000"
        }
      ]
    },
{
  "nombre": "Lo Mien",
  "items": [
    {
      "nombre_plato": "Lomien Langostino",
      "descripcion_plato": "Pasta con Langostino.",
      "precio_plato": "$72.000"
    },
    {
      "nombre_plato": "Lomien Pollo",
      "descripcion_plato": "Pasta con Camarones.",
      "precio_plato": "$56.000"
    },
    {
      "nombre_plato": "Lomien Res",
      "descripcion_plato": "Pasta con Res.",
      "precio_plato": "$58.000"
    }
  ]
},
{
  "nombre": "Plato ToFu",
  "items": [
    {
      "nombre_plato": "ToFu Pollo",
      "precio_plato": "$58.000"
    },
    {
      "nombre_plato": "ToFu Res",
      "precio_plato": "$60.000"
    },
    {
      "nombre_plato": "Mapo To Fu",
      "precio_plato": "$61.000"
    },
    {
      "nombre_plato": "ToFu Langostino",
      "precio_plato": "$74.000"
    }
  ]
},

{
  "nombre": "Menú Infantil",
  "items": [
    {
      "nombre_plato": "Papas a la francesa y croquetas de pollo con salsa agridulce",
      "precio_plato": "$"
    }
  ]
},
{
  "nombre": "Carnes",
  "items": [
    {
      "nombre_plato": "Res Pimentón",
      "descripcion_plato": "Res, cebolla, pimentón, salsa de soya.",
      "precio_plato": "$60.000"
    },
    {
      "nombre_plato": "Pollo Plancha o Milanesa",
      "descripcion_plato": "Papas fritas y ensalada.",
      "precio_plato": "$60.000"
    },
    {
      "nombre_plato": "Solomito con Vegetales en Salsa de Vino",
      "descripcion_plato": "$61.000"
    },
    {
      "nombre_plato": "Filet Mignon con Champiñones",
      "descripcion_plato": "Papas a la francesa.",
      "precio_plato": "$61.000"
    },
    {
      "nombre_plato": "Solomito Asado",
      "descripcion_plato": "Papas fritas y ensalada.",
      "precio_plato": "$61.000"
    },
    {
      "nombre_plato": "Cerdo Asado",
      "descripcion_plato": "Papas fritas y ensalada.",
      "precio_plato": "$60.000"
    }
  ]
},
{
  "nombre": "Postres",
  "items": [
    {
      "nombre_plato": "Flan Coco",
      "precio_plato": "$20.000"
    },
    {
      "nombre_plato": "Flan Caramelo",
      "precio_plato": "$20.000"
    },
    {
      "nombre_plato": "Cheese Cake",
      "precio_plato": "$20.000"
    },
    {
      "nombre_plato": "Nata",
      "precio_plato": "$20.000"
    },
    {
      "nombre_plato": "Tapioca con Mango",
      "precio_plato": "$21.000"
    }
  ]
},
 
  ]
};



export const Cartachino = {
  "categorias": [
    {
      "nombre": "清爽饮品",
      "items": [
        {
          "nombre_plato": "茶Hatsu",
          "descripcion_plato": "绿茶，粉红色，红茶，白茶，紫茶。",
          "precio_plato": " $"
        },
        {
          "nombre_plato": "可口可乐",
          "descripcion_plato": "零度，健怡，可口可乐，雪碧。",
          "precio_plato": " $"
        },
        {
          "nombre_plato": "波士顿",
          "descripcion_plato": "苹果，7UP，橙汁。",
          "precio_plato": " $"
        },
        {
          "nombre_plato": "果味汽水",
          "descripcion_plato": "西瓜，百香果，荔枝。",
          "precio_plato": " $"
        }
      ]
    },

    {
      "nombre": "开胃菜",
      "items": [
        {
          "nombre_plato": "春卷",
          "descripcion_plato": "炸卷，肉和蔬菜。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "烤排骨",
          "descripcion_plato": "多汁的烤排骨，配烧烤酱。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "炸云吞",
          "descripcion_plato": "炸云吞脆饼（不含肉或蔬菜）。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁鸡尾酒",
          "descripcion_plato": "粉红酱汁中的美味虾仁，去壳，干净，新鲜。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁杯",
          "descripcion_plato": "杯中的新鲜虾仁。",
       "precio_plato": ""
        },
        {
          "nombre_plato": "招牌拼盘",
          "descripcion_plato": "各种肉类，鸡肉，猪肉，虾仁，春卷，排骨和炸云吞的拼盘。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "鹌鹑蛋杯",
          "descripcion_plato": "杯中的鹌鹑蛋。",
           "precio_plato": ""
        }
      ]
    },

    {
      "nombre": "汤",
      "items": [
        {
          "nombre_plato": "酸辣汤",
          "descripcion_plato": "猪肉块，豆腐，微辣。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "云吞汤",
          "descripcion_plato": "肉和蔬菜与面条和鸡蛋的汤。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "一口面",
          "descripcion_plato": "鸡肉汤，面条和蔬菜。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁奶油汤",
          "descripcion_plato": "奶油汤，虾仁块。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "龙虾奶油汤",
          "descripcion_plato": "奶油汤，龙虾块。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "洋葱奶油汤",
          "descripcion_plato": "奶油汤，洋葱块。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "西兰花奶油汤",
          "descripcion_plato": "奶油汤，西兰花块。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "鸡肉奶油汤",
          "descripcion_plato": "奶油汤，鸡肉块。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "鱼块奶油汤",
          "descripcion_plato": "奶油汤，鱼块。",
          "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "Chinese Rice",
      "items": [
        {
          "nombre_plato": "鸡肉炒饭",
          "descripcion_plato": "鸡肉，山药，洋葱的炒饭。",
        
        },
        {
          "nombre_plato": "牛肉饭",
          "descripcion_plato": "牛肉的饭。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁炒饭",
          "descripcion_plato": "只有虾仁的广东炒饭。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "海鲜饭",
          "descripcion_plato": "海鲜的饭。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾炒饭",
          "descripcion_plato": "虾的炒饭。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "八味炒饭",
          "descripcion_plato": "虾，牛肉，鱿鱼，鸡肉，虾，猪肉，鱼和火腿的炒饭。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "特色炒饭",
          "descripcion_plato": "特色的中式炒饭，配有鸡肉，猪肉，火腿和鸡蛋（只有特色炒饭包括黄蛋）。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "半份特色炒饭",
          "descripcion_plato": "小份的特色炒饭，配有鸡肉，猪肉，火腿。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "特色炒饭配虾仁",
          "descripcion_plato": "特色的中式炒饭，配有鸡肉，猪肉，火腿和虾仁。",
          "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "特色菜",
      "items": [
        {
          "nombre_plato": "橙味鸡",
          "descripcion_plato": "美味的鸡胸块浸泡在橙汁中。",
     "precio_plato": ""
        },
        {
          "nombre_plato": "菠萝鸡",
          "descripcion_plato": "新鲜的菠萝块搭配精致的鸡肉。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "杂碎菜",
          "descripcion_plato": "虾，牛肉，豆腐，鸡肉，虾，猪肉，鱼和各种蔬菜。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "西兰花鸡",
          "descripcion_plato": "鸡肉在豉油上的西兰花床上。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "西兰花牛肉",
          "descripcion_plato": "牛肉在豉油上的西兰花床上。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "西兰花虾",
          "descripcion_plato": "豉油西兰花床上的炒虾。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "海鲜芝士汁鸡",
          "descripcion_plato": "鸡肉浸泡在海鲜和芝士酱中。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "珍珠鸡",
          "descripcion_plato": "珍珠鸡在玉米粒床上。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "珍珠鸡牛肉",
          "descripcion_plato": "牛肉在玉米粒床上。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁炒菜",
          "descripcion_plato": "香料炒虾。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "牛肉姜汁蚝油酱",
          "descripcion_plato": "美味的牛肉块浸泡在酱汁中。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "蒜蓉土豆炒虾",
          "descripcion_plato": "大蒜与蒸土豆和虾。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "培根辣椒",
          "descripcion_plato": "细碎的培根搭配超辣的辣椒。",
           "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "甜酸酱",
      "items": [
        {
          "nombre_plato": "甜酸鸡",
          "descripcion_plato": "甜酸鸡酱与鸡肉。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "甜酸猪肉",
          "descripcion_plato": "甜酸猪肉酱与猪肉。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "甜酸虾",
          "descripcion_plato": "甜酸虾酱与虾。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "甜酸鱼",
          "descripcion_plato": "甜酸鱼酱与鱼。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "甜酸排骨",
          "descripcion_plato": "甜酸排骨酱与排骨。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "小份甜酸鸡",
          "descripcion_plato": "小份甜酸鸡酱与鸡肉。",
           "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "咖喱酱",
      "items": [
        {
          "nombre_plato": "咖喱鸡",
          "descripcion_plato": "咖喱鸡。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "咖喱虾",
          "descripcion_plato": "咖喱虾。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "咖喱鱼",
          "descripcion_plato": "咖喱鱼。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "咖喱虾",
          "descripcion_plato": "咖喱虾。",
           "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "蚝油酱",
      "items": [
        {
          "nombre_plato": "蚝油鸡",
          "descripcion_plato": "蚝油鸡。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "蚝油牛肉",
          "descripcion_plato": "蚝油牛肉。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "蚝油虾",
          "descripcion_plato": "蚝油虾。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "蚝油鱼",
          "descripcion_plato": "蚝油鱼。",
          "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "黑椒酱",
      "items": [
        {
          "nombre_plato": "黑椒牛肉",
          "descripcion_plato": "黑椒牛肉。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "黑椒虾",
          "descripcion_plato": "黑椒虾。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "黑椒鱼",
          "descripcion_plato": "黑椒鱼。",
           "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "素食",
      "items": [
        {
          "nombre_plato": "蔬菜炒面",
          "descripcion_plato": "蔬菜炒面。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "蔬菜炒饭",
          "descripcion_plato": "蔬菜炒饭。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "三宝炒饭",
          "descripcion_plato": "蔬菜炒饭，配有三种蔬菜。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "西兰花炒蘑菇",
          "descripcion_plato": "西兰花炒蘑菇。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "芦笋炒蘑菇",
          "descripcion_plato": "芦笋炒蘑菇。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "白菜炒蘑菇",
          "descripcion_plato": "白菜炒蘑菇。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "辣椒豆腐",
          "descripcion_plato": "辣椒豆腐。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "红豆沙",
          "descripcion_plato": "红豆沙甜汤。",
           "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "面食",
      "items": [
        {
          "nombre_plato": "云吞面",
          "descripcion_plato": "肉和蔬菜与面条和鸡蛋的汤。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "鸡肉云吞面",
          "descripcion_plato": "鸡肉和蔬菜与面条和鸡蛋的汤。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁云吞面",
          "descripcion_plato": "虾仁和蔬菜与面条和鸡蛋的汤。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁面",
          "descripcion_plato": "虾仁和蔬菜与面条和鸡蛋的汤。",
         "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "炸鸡",
      "items": [
        {
          "nombre_plato": "香辣鸡",
          "descripcion_plato": "香辣炸鸡。",
         "precio_plato": ""
        },
        {
          "nombre_plato": "原味鸡",
          "descripcion_plato": "原味炸鸡。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "鸡翅膀",
          "descripcion_plato": "炸鸡翅膀。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "鸡腿",
          "descripcion_plato": "炸鸡腿。",
          "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "配菜",
      "items": [
        {
          "nombre_plato": "白饭",
          "descripcion_plato": "白饭。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "炒面",
          "descripcion_plato": "炒面。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "炒饭",
          "descripcion_plato": "炒饭。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "薯条",
          "descripcion_plato": "薯条。",
        "precio_plato": ""
        },
        {
          "nombre_plato": "炸云吞",
          "descripcion_plato": "炸云吞。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "白米饭",
          "descripcion_plato": "白米饭。",
        "precio_plato": ""
        },
        {
          "nombre_plato": "鸡蛋面",
          "descripcion_plato": "鸡蛋面。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "鸡蛋炒饭",
          "descripcion_plato": "鸡蛋炒饭。",
          "precio_plato": ""
        }
      ]
    },
    {
      "nombre": "汤面",
      "items": [
        {
          "nombre_plato": "鸡蛋汤面",
          "descripcion_plato": "鸡蛋和面条的汤。",
        "precio_plato": ""
        },
        {
          "nombre_plato": "鸡蛋炒汤面",
          "descripcion_plato": "鸡蛋和面条的炒汤。",
           "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁汤面",
          "descripcion_plato": "虾仁和面条的汤。",
          "precio_plato": ""
        },
        {
          "nombre_plato": "虾仁炒汤面",
          "descripcion_plato": "虾仁和面条的炒汤。",
          "precio_plato": " "
        }
      ]
    }
  ]
};


export const Cartausa = {
  "categories": [
    {
      "name": "Refreshing Drinks",
      "items": [
        {
          "dish_name": "Te Hatsu",
          "dish_description": "Green, Pink, Black, Yellow, White, Lilac.",
          "dish_price": "$ 11,000"
        },
        {
          "dish_name": "Coca-Cola",
          "dish_description": "Zero, Diet, Coca-Cola, Sprite.",
          "dish_price": "$"
        },
        {
          "dish_name": "Postobón",
          "dish_description": "Apple, 7UP, Colombiana.",
          "dish_price": "$"
        },
        {
          "dish_name": "Flavored Sodas",
          "dish_description": "Watermelon, Passion Fruit, Lychee.",
          "dish_price": "$"
        }
      ]
    },
  
    {
      "name": "Appetizers",
            "items": [
        {
          "dish_name": "Lumpia",
          "dish_description": "Pork and vegetable fried roll with sweet and sour sauce...",
          "dish_price":"$ 9.500"
        },
        {
          "dish_name": "Costilla Asada",
          "dish_description": "Juicy barbecued ribs with barbecue sauce.",
          "dish_price":"$ 53,000"
        },
        {
          "dish_name": "Wan Tum Frito",
          "dish_description": "Fried ojuelas (no vegetables or meat).",
          "dish_price": "$ 26,500"
        },
        {
          "dish_name": "Prawn Cup",
          "dish_description": "Cup with delicious prawns in pink sauce.",
          "dish_price":"$ 68,000"
        },
        {
          "dish_name": "Shrimp Cup",
          "dish_description": "Cup with fresh shrimp.",
          "dish_price":"$ 59.000"
        },
        {
          "dish_name": "House Combination",
          "dish_description": "Assorted plate of meats, chicken, pork, prawns, lumpias, ribs and fried Wanton.",
          "dish_price":"$ 68.000"
        },
        {
          "dish_name": "Quail Egg Cup",
          "dish_description": "Quail eggs in pink sauce.",
          }, { "dish_price": "$ 24.000"
        }
      ]
    },

    {
      "name": "Soups",
      "items": [
        {
          "dish_name": "Hot and Sour",
          "dish_description": "Soup of small pieces of pork and Tofu, spicy.",
          "dish_price":"$ 22.500"
        },
        {
          "dish_name": "Wanton",
          "dish_description": "Lovely Asian soup, with soy sauce to taste.",
          "dish_price":"$ 22.500"
        },
        {
          "dish_name": "Yat Ko Mien",
          "dish_description": "Ham pork soup with noodles and egg.",
          "dish_price":"$ 22.500"
        },
     {
  "dish_name": "Sea Food Soup",
  "dish_description": "Chinese Soup.",
  "dish_price": "$ 25.500"
}

      ]
    },

    {
      "name": "Cream Soup",
      "items": [
       {
          "dish_name": "Shrimp Cream Soup",
          "dish_description": "Cream with shrimp pieces.",
          "dish_price":"$ 24,000"
        },
        {
          "dish_name": "Prawn Cream",
          "dish_description": "Cream with prawn pieces.", 
          "dish_price":"$ 26,500"
        },
        {
          "dish_name": "Onion Cream",
          "dish_description": "Cream with Onion Chips.", 
          "dish_price":"$ 20,500"
        },
        {
          "dish_name": "Cream of Broccoli Cream",
          "dish_description": "Cream with small pieces of Broccoli.",
          "dish_price":"$ 20,500"
        },
        {
          "dish_name": "Chicken Cream Soup",
          "dish_description": "Cream with chicken chunks.",
          "dish_price":"$ 22,500"
        },
        {
          "dish_name": "Fish Cream",
          "dish_description": "Cream with bits of fish.",
          "dish_price": "$ 22,500"
        },
      ]
    },

    {
      "name": "Special Dishes",
      "items": [
        {
          "dish_name": "Orange Chicken",
          "dish_description": "Delicious chicken breast in chunks bathed in orange sauce.",
          "dish_price": "$ 59,000"
        },
        {
          "dish_name": "Chaw Pat Chin",
          "dish_description": "Shrimp, Tofu, Chicken, Pork, Ham, and a variety of Vegetables.",
          "dish_price": "$ 60,000"
        },
        {
          "dish_name": "Broccoli Chicken",
          "dish_description": "Chicken in seafood sauce on a bed of broccoli.",
          "dish_price": "$ 59,000"
        },
        {
          "dish_name": "Broccoli Beef",
          "dish_description": "Beef in seafood sauce on a bed of broccoli.",
          "dish_price": "$ 61,000"
        },
        {
          "dish_name": "Sesame Chicken in Seafood Sauce",
          "dish_description": "Chicken bathed in seafood and sesame sauce.",
          "dish_price": "$ 59,000"
        },
        {
          "dish_name": "Sautéed Langostinos",
          "dish_description": "Langostinos in spices and salt.",
          "dish_price": "$ 74,000"
        },
        {
          "dish_name": "Beef with Ginger in Oyster Sauce",
          "dish_description": "Delicious pieces of beef in oyster sauce.",
          "dish_price": "$ 60,000"
        },
        {
          "dish_name": "Garlic Steamed Shrimp with Potatoes",
          "dish_description": "Garlic with steamed potatoes and shrimps.",
          "dish_price": "$ 62,000"
        },
        {
          "dish_name": "Bacon with Jalapeños",
          "dish_description": "Finely chopped bacon with extra spicy jalapeños.",
          "dish_price": "$ 61,000"
        },
        {
  "dish_name": "Spicy Beef Stew",
  "dish_description": "Spicy beef stew.",
  "dish_price": ""
}

      ]
    },







{
      "name": "Chinese Fried Rice",
      "items": [
        {
          "dish_name": "Chinese Chicken Fried Rice ",
          "dish_description": "Fried rice with chicken, mogo roots, and onion.",
          "dish_price": "$ 55,000 "
        },
        {
          "dish_name": "Beef Fried Rice",
          "dish_description": "Fried rice with beef.",
          "dish_price": "$ 58,000"
        },
     
 {
  "dish_name": "Prawn Rice",
  "dish_description": "Cantonese rice with only prawns.",
  "dish_price": "$ 72,000"
},
  {
    "dish_name": "Rice with Only Shrimp",
    "dish_description": "Fried rice with shrimp.",
    "dish_price": "$ 62,000"
  },
  {
    "dish_name": "8-Flavor Rice",
    "dish_description": "Stir-fried rice with shrimp, beef, chicken, shrimp, pork, and ham.",
    "dish_price": "$ 62,000"
  },
  {
    "dish_name": "Special Rice",
    "dish_description": "Special Chinese rice with chicken, pork, ham, and egg (only the special rice has yellow egg).",
    "dish_price": "$ 53,000"
  },
  {
    "dish_name": "Half Special Rice with Shrimp",
    "dish_description": "Special Chinese rice with chicken, pork, ham + shrimp.",
    "dish_price": "$ 44,000"
  }
      ]
    },





    {
      "name": "Sweet and Sour Sauce",
      "items": [
          {
          "dish_name": "Sweet & sour sauce with chicken",
          "dish_description": "Tender chicken croquettes, bathed in delicious sweet and sour sauce.",
          "dish_price":"$ 55,000"
        },
        {
          "dish_name": "Sweet and sour sauce pork",
          "dish_description": "Selected pork croquettes, bathed in delicious sweet and sour sauce.",
          "dish_price":"$ 55,000"
        },
        {
          "dish_name": "Sweet and sour sauce prawns",
          "dish_description": "Croquettes of delicious prawns, bathed in sweet and sour sauce.",
          "dish_price":"$ 72,000"
        },
        {
          "dish_name": "Sweet and sour sauce fish",
          "dish_description": "Fresh fish croquettes, dipped in sweet and sour sauce.",
          "dish_price":"$ 55,000"
        },
        {
          "dish_name": "Sweet and sour sauce ribs.",
          "dish_description": "Rib croquettes, dipped in sweet and sour sauce.",
          "dish_price":""
        },
        {
          "dish_name": "Half sweet and sour sauce chicken",
          "dish_description": "Sweet and sour sauce with delicious chicken.",
          "dish_price":""
        }
      ]
    },

    {
      "name": "Curry Sauce",
      "items": [
      {
    "dish_name": "Chicken Curry Sauce",
    "dish_description": "Chicken with curry sauce, steamed potatoes, and onion.",
    "dish_price": "$ 56,000 "
  },
  {
    "dish_name": "Prawn Curry Sauce",
    "dish_description": "Prawn with curry sauce, steamed potatoes, and onion.",
    "dish_price": "$ 72,000"
  },
  {
    "dish_name": "Fish Pescado Curry Sauce",
    "dish_description": "Fish with curry sauce, steamed potatoes, and onion.",
    "dish_price": "$ 59,000"
  }
      ]
    },


    {
      "name": "Oyster Sauce",
      "items": [
        {
          "dish_name": "Chicken with Oyster Sauce",
          "dish_description": "Chicken with oyster sauce, Chinese roots, and onion.",
          "dish_price": "$"
        },
        {
          "dish_name": "Pork with Oyster Sauce",
          "dish_description": "Pork with oyster sauce, Chinese roots, and onion.",
          "dish_price": "$"
        },
        {
          "dish_name": "Beef with Oyster Sauce",
          "dish_description": "Beef with oyster sauce, Chinese roots, and onion.",
          "dish_price": "$"
        },
        {
          "dish_name": "Prawn with Oyster Sauce",
          "dish_description": "Prawn with oyster sauce, Chinese roots, and onion.",
          "dish_price": "$"
        },
        {
          "dish_name": "Fish with Oyster Sauce",
          "dish_description": "Fish with oyster sauce, Chinese roots, and onion.",
          "dish_price": "$",
        }
      
      ]
    },
    {
      "name": "Chop Suey",
      "items": [
        {
          "dish_name": "Chop Suey with Chicken",
          "dish_description": "Chicken and vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Chop Suey with Beef",
          "dish_description": "Beef and vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Chop Suey with Prawn",
          "dish_description": "Prawn and vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Chop Suey with Seafood",
          "dish_description": "Seafood like fish, shrimp, and langostinos with vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Chop Suey with Shrimp",
          "dish_description": "Shrimp and vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Special Chop Suey with Chicken, Pork, Ham",
          "dish_description": "Special Chop Suey with Chicken, Pork, Ham, and vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Half Chop Suey with Chicken, Pork, Ham",
          "dish_description": "Small Chop Suey with Chicken, Pork, Ham, and vegetables in soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Half special Chop Suey with Chicken, Pork, Ham + Shrimp",
          "dish_description": "Special Chop Suey with Chicken, Pork, Ham + Shrimp, and vegetables in soy sauce.",
          "dish_price": "$"
        }
      ]
    },
    {
      "name": "Vegetarian Dishes",
      "items": [
        {
          "dish_name": "Meatless Lomien",
          "dish_price": "$"
        },
        {
          "dish_name": "Fried Rice with Roots and Egg",
          "dish_price": "$"
        },
        {
          "dish_name": "Vegetables in Soy Sauce",
          "dish_price": "$"
        },
        {
          "dish_name": "Tofu in Sweet and Sour Sauce",
          "dish_price": "$"
        }
      ]
    },
    {
      "name": "Seafood",
      "items": [

{
          "dish_name": "Butterfly Prawns",
          "dish_description": "Delicious prawns prepared butterfly style with bacon and French fries.",
          "price_dish":"$"
        },
        {
          "dish_name": "Grilled Prawns",
          "dish_description": "Fresh grilled prawns with an Asian touch and french fries.",
          "dish_price":"$"
        },
        {
          "dish_name": "Garlic Prawn",
          "dish_description": "Garlic sautéed prawns and steamed potato.",
          "dish_price": "$"
        },
        {
          "dish_name": "Seafood Casserole",
          "dish_description": "Exquisite casserole with a variety of fresh seafood such as fish, prawns and shrimp in our house red sauce.",
          "precio_plato":"$"
        },
        {
          "dish_name": "Fish with Vegetables in Soy Sauce",
          "dish_description": "Fresh fish fillet accompanied by sautéed vegetables in soy sauce.",
          "dish_price":"$"
        },
        {
         "dish_name": "Breaded Fish",
          "dish_description": "Fish breaded and browned to perfection, served with french fries and salad.",
          "dish_price":"$"
        },
        {
          "dish_name": "Grilled Fish",
          "dish_description": "Grilled fish fillet with french fries and salad.",
          "dish_price":"$"
        }

      ]
    },




    {
      "name": "Combination Dishes ",
      "items": [
        {
          "dish_name": "Chop Suey and Special Rice",
          "dish_description": "Fried Rice with Pork, Chicken, and Ham + Chinese Vegetables",
            "dish_price": "$"
        },
        {
          "dish_name": "Chop Suey and Sweet and Sour Pork",
          "dish_description": "Vegetables and sweet and sour pork croquettes.",
          "dish_price": "$"
        },
        {
          "dish_name": "Special Rice and Sweet and Sour Pork",
          "dish_description": "Fried rice with pork, chicken, ham, and sweet and sour pork croquettes.",
          "dish_price": "$"
        },
        {
          "dish_name": "Special Rice and Sweet and Sour Chicken",
          "dish_description": "Fried rice with pork, chicken, ham, and sweet and sour chicken croquettes.",
          "dish_price": "$"
        },
        {
          "dish_name": "Triple Chicken",
          "dish_description": "Chop Suey + Fried rice with pork, chicken, ham, and sweet and sour chicken croquettes.",
          "dish_price": "$"
        },
        {
          "dish_name": "Triple Pork",
          "dish_description": "Chop Suey + Fried rice with pork, chicken, ham, and sweet and sour pork croquettes.",
          "dish_price": "$"
        }
      ]
    },

    
    {
      "name": "Lo Mien",
      "items": [
    
  {
    "dish_name": "Prawn Lo Mein",
    "dish_description": "Pasta with prawns.",
    "dish_price": "$"
  },
  {
    "dish_name": "Chicken Lo Mein",
    "dish_description": "Pasta with chicken.",
    "dish_price": "$"
  },
  {
    "dish_name": "Beef Lo Mein",
    "dish_description": "Pasta with beef.",
    "dish_price": "$"
  }
      ]
    },
    {
      "name": "ToFu Dishes",
      "items": [
        {
          "dish_name": "ToFu Chicken",
          "dish_price": "$"
        },
        {
          "dish_name": "ToFu Beef",
          "dish_price": "$"
        },
        {
          "dish_name": "Mapo ToFu",
          "dish_price": "$"
        },
        {
          "dish_name": "ToFu Prawn",
          "dish_price": "$"
        }
      ]
    },
    {
      "name": "Kids' Menu",
      "items": [
        {
          "dish_name": "French Fries and chicken croquettes with separate sweet and sour sauce",
          "dish_price": "$"
        }
      ]
    },
    {
      "name": "Meats",
      "items": [
        {
          "dish_name": "Pepper Steak",
          "dish_description": "Steak, onion, bell pepper, soy sauce.",
          "dish_price": "$"
        },
        {
          "dish_name": "Grilled or Breaded Chicken",
          "dish_description": "French fries and salad.",
          "dish_price": "$"
        },
        {
          "dish_name": "Sirloin Steak with Wine Sauce Vegetables",
          "dish_description": "$"
        },
        {
          "dish_name": "Filet Mignon with Mushrooms",
          "dish_description": "French fries.",
          "dish_price": "$"
        },
        {
          "dish_name": "Grilled Sirloin Steak",
          "dish_description": "French fries and salad.",
          "dish_price": "$"
        },
        {
          "dish_name": "Roasted Pork",
          "dish_description": "French fries and salad.",
          "dish_price": "$"
        }
      ]
    },
    {
      "name": "Desserts",
      "items": [
        {
          "dish_name": "Coconut Flan",
          "dish_price": "$"
        },
        {
          "dish_name": "Caramel Flan",
          "dish_price": "$"
        },
        {
          "dish_name": "Cheesecake",
          "dish_price": "$"
        },
       
        {
          "dish_name": "Tapioca with Mango",
          "dish_price": "$"
        }
      ]
    },
    
  
    {
      "name": "Tea",
      "items": [
 
        {
          "dish_name": "Chinese Tea",
          "dish_price": "$"
        }
      ]
    }
  ]
};

const exportObject = {
  MenuList,
  Cartaespañol,
  Cartachino,
  Cartausa,
};

export default exportObject;
