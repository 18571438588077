import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import "../styles/HomeStyles.css";
import MiniLayout from "./Section";
import Mapa from "../components/Layout/Mapa";
import Horarios from "../components/Piaces/Horarios";
import Figura from "../components/Piaces/Figura";
const Home = () => {
  const [backgroundLoaded] = useState(true);
  return (
    <Layout>
      <div className={`home ${backgroundLoaded ? 'loaded' : ''}`}>
        {backgroundLoaded && (
          <div className="headerContainer">
            <h2>Restaurante Asia</h2>
            <h3>Disfruta de la auténtica comida china en Medellín.</h3>
            <h3>Explora nuestra variedad de platos asiáticos e internacionales.</h3>
            <h3>Gastronomía Asiática Desde 1980</h3>
            <Link to="/menu">
              <button>Ver Menú</button>
            </Link>
          </div>
        )}
      </div>
<MiniLayout />     
      <div className="carusel">
        <h1 className="titulo_carusel">Restaurante Chino en las Palmas del Poblado Medellín</h1>
        <h2 className="titulo_carusell">Domicilios para Sabaneta, La Estrella, Laureles, Envigado, Mall Indiana, Bello, Belén, Aranjuez, Itagüí.</h2>
      </div>
<Figura/>
      <div className="cards-container">
      {/* Tarjeta para Comer en el Salón */}
      <div className="card">
        <h2>Comer en el Salón</h2>
        <p>Disfrute de una comida en nuestro acogedor salón.</p>
        <a href="/Salon" className="card-button">Ver más</a>
      </div>
    </div>
<Horarios/>
<Figura/>
      <Mapa />
        <div className="keywords">
        <div className="fondo">
          <p>Estamos encantados de ofrecerte una experiencia gastronómica inigualable en el Poblado Medellín.</p>
          <div className="fondo_center">
            <span>
              Nuestro exquisito <h2>arroz chino</h2> y <h2>chopsuey</h2> están preparados con auténticos sabores e ingredientes frescos.
            </span>
          </div>
          <p>Si prefieres disfrutar de nuestros platos en la comodidad de tu hogar, también ofrecemos servicio a domicilio para todo el Valle de Aburra.</p>
        </div>
      </div>
    </Layout>
  );
};
export default Home;
