import React from "react";
import { Fab, Box } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppButton = () => {
  const phoneNumber = "3044936299"; // Aquí colocas tu número de teléfono
  const message = "Hola, quiero ordenar. Vengo de restauranteasia.com.co"; // Aquí colocas el mensaje predeterminado

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 100,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Fab
        sx={{
          bgcolor: "#00BB2D",
          width: "45px",
          height: "45px",
          "&:hover": { bgcolor: "#00BB2D" },
        }}
        aria-label="whatsapp"
        onClick={handleClick}
      >
        <WhatsAppIcon sx={{ color: "white", fontSize: "28px" }} />
      </Fab>
    </Box>
  );
};

export default WhatsAppButton;
