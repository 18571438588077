import React, { useEffect, useState } from 'react';
import './Mapa.css'; // Archivo CSS para estilos personalizados

function Mapa() {
  const [mapLoaded, setMapLoaded] = useState(false);

  const handleWheel = (e) => {
    if (!mapLoaded) {
      e.preventDefault();
    }
  };

  const handleTouchStart = (e) => {
    if (!mapLoaded) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      // Código a ejecutar cuando la página web se haya cargado completamente
      console.log('La página se ha cargado completamente.');
      // Ahora que la página se ha cargado, puedes habilitar la carga del mapa.
      setMapLoaded(true);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div>
 {/* Encabezado del restaurante */}
<div
  style={{
    backgroundColor: "#FFD700", /* Color amarillo típico en restaurantes chinos */
    textAlign: "center",
    padding: "20px",
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#333", /* Color de texto principal */
    marginBottom: "20px",
  }}
>
  <h2>¡Descubre el Sabor Único del Arroz Chino en Medellín!</h2>
</div>

{/* Descripción del restaurante */}
<div
  style={{
    textAlign: "center",
    fontSize: "1.2rem",
    color: "#666", /* Color de texto secundario */
    marginBottom: "20px",
    marginLeft: "10px",
    marginRight: "10px",
  }}
>
  <p style={{ lineHeight: "1.5" }}>
    En el restaurante Asia, te invitamos a disfrutar de una auténtica experiencia gastronómica asiática en Medellín.
    Nuestro plato estrella es el delicioso arroz chino, preparado con ingredientes frescos y sazonado a la perfección.
    ¡Satisfaceremos tus antojos con sabores auténticos que te transportarán directamente a China!
  </p>
  <p style={{ lineHeight: "1.5" }}>
    Nuestra ubicación privilegiada en Medellín hace que sea conveniente para ti visitarnos y saborear los mejores
    platos asiáticos. Ya sea que busques un almuerzo rápido o una cena especial, nuestro restaurante es la elección perfecta.
  </p>
  <p style={{ lineHeight: "1.5" }}>
    ¡No esperes más para probar nuestra cocina excepcional y los sabores únicos que solo el restaurante Asia puede ofrecerte!
  </p>
  {/* Agrega aquí información sobre el menú, horarios de atención y cómo realizar un pedido para impulsar la intención de compra */}
</div>

      {/* Contenedor del mapa */}
      <div className="map-container">
        {!mapLoaded && (
          <div className="map-loading">Cargando mapa...</div>
        )}
        {mapLoaded && (
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63460.26510423153!2d-75.61806274868455!3d6.228544056264483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4428257bbb0b75%3A0x13ca03837160f18a!2sRestaurante%20Asia!5e0!3m2!1ses!2sco!4v1694989469313!5m2!1ses!2sco"
            width="100%"
            height="300"
            className="map-iframe"
            allowFullScreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            onWheel={handleWheel}
            onTouchStart={handleTouchStart}
            alt="Mapa de ubicación del restaurante Asia en Medellín"
          ></iframe>
        )}
      </div>

      {/* Pie de página o información adicional */}
      <div className="footer">
        {/* Agrega aquí información adicional o enlaces */}
      </div>
    </div>
  );
}

export default Mapa;
