import React from 'react';
import "../../styles/MiniFooter.css";
const MiniFooter = () => {
  return (
    <div className="mini-footer">
      <a href="http://www.amkydigital.com" target="_blank" rel="noreferrer">
        <p>Hecho en Medellín  &copy;  Amkydigital.com</p>
      </a>
    </div>
  );
};
export default MiniFooter;
