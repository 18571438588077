import React, { useState } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-image-gallery";
import Typography from "@mui/material/Typography";
import Layout from "../components/Layout/Layout";
import "react-image-gallery/styles/css/image-gallery.css";
import a from "../images/a.webp";
import b from "../images/b.webp";
import d from "../images/d.webp";
import "./Salon.css";
import adjustedImage from "../images/Draguns.webp";
import Figura from "../components/Piaces/Figura";
function convertToDecimal(percentage) {
 return percentage / 28;
}
const containerStyle = {
  textAlign: "center",
  padding: "20px",
  width: "100%", 
  borderRadius: "10px",
  backgroundSize: "contain", 
  backgroundRepeat: "no-repeat", // Corregido de "noRepeat"
  backgroundPosition: "center", // Ajustar según sea necesario
  opacity: 1,
};
const whiteColorWithOpacity = convertToDecimal(20);
const textStyle = {
  fontSize: '1.6rem',
  lineHeight: '1.6',
  color: '#000000',  // Corregido de '#00000'
backgroundColor: `rgba(255, 255, 255, ${whiteColorWithOpacity})`,
  fontWeight: "bold",  // Corregido de "blond"
  zIndex: "2", // Este es el índice z más alto
};
const images = [
  {
    original: a,
    description: "Descripción de la imagen 1",
  },
  {
    original: b,
    description: "Descripción de la imagen 2",
  },
  {
    original: d,
    description: "Descripción de la imagen 3",
  },
  // Agregar más imágenes según sea necesario
];
const Salon = () => {
  const phoneNumber = "3044936299";
  const [showGallery] = useState(true);
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const containerul = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh', // Ajustar según sea necesario
  };
  return (
    <Layout>
      <div className="salon-container">
        <header className="salon-header">
          <Typography
            variant="h1"
            sx={{
              marginTop: "1rem",
              fontSize: "6rem",
              fontWeight: "bold",
              color: "#D32F2F",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              "@media (max-width: 768px)": {
                fontSize: "3.6rem",
              },
              "@media (max-width: 420px)": {
                fontSize: "3rem",
              },
              "@media (max-width: 390px)": {
                fontSize: "2.4rem",
              },
            }}
          >
            Disfruta de Nuestro Salón de Eventos
          </Typography>
        </header>
        <main className="salon-main">
          <section className="salon-banner">
            <p className="banner-text banner-text-large">
              Celebra tus eventos especiales con nosotros
            </p>
            <p className="banner-text banner-text-small">
              Eventos, fiestas de cumpleaños, aniversarios, reuniones empresariales y más.
            </p>
            <p className="banner-text banner-text-small">¡Reserva ahora!</p>
            <Link to="/menu" className="menu-button">
              Ver Menú
            </Link>
          </section>
          <section className="salon-info" style={{ backgroundImage: `url(${adjustedImage})`, ...containerStyle }}>
            <div>
              <p style={textStyle}>
                En el Restaurante Asia, te brindamos la experiencia perfecta para tus eventos especiales. Nuestro amplio salón es ideal para celebrar fiestas de cumpleaños, aniversarios, reuniones empresariales y mucho más. Además, contamos con amplios parqueaderos y seguridad privada.
              </p>
              <p style={textStyle}>
                Nuestro restaurante en Las Palmas ofrece una vista panorámica excepcional y una ubicación privilegiada en la ciudad de Medellín, para que disfrutes de un pedazo de China en un ambiente exclusivo.
              </p>
              <p style={textStyle}>
                También proporcionamos servicios como WiFi de alta velocidad y una amplia variedad de opciones gastronómicas asiáticas en nuestro menú. ¡Te esperamos para crear momentos inolvidables junto a tu familia y amigos!
              </p>
            </div>
          </section>
          <div style={{ marginTop: '20px' }}>
            <Figura />
          </div>
          <section className="salon-events" style={{ marginTop: '40px', textAlign: 'center' }}>
            <h2 className="section-title" style={{ fontSize: '2rem', marginBottom: '20px' }}>Celebra con Nosotros</h2>
            <p style={{ fontSize: '1.2rem', color: '#555', marginBottom: '20px' }}>
              En Restaurante Asia, ofrecemos un espacio perfecto para diversos eventos. Celebra momentos especiales con nosotros, incluyendo:
            </p>
            <div style={containerul}>
             <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
  <li style={{ fontSize: '1.2rem', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span role="img" aria-label="Checkmark" style={{ marginRight: '8px' }}>
      ✅
    </span>
    <span>Día de la Madre</span>
  </li>
  <li style={{ fontSize: '1.2rem', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span role="img" aria-label="Checkmark" style={{ marginRight: '8px' }}>
      ✅
    </span>
    <span>Cumpleaños</span>
  </li>
  <li style={{ fontSize: '1.2rem', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span role="img" aria-label="Checkmark" style={{ marginRight: '8px' }}>
      ✅
    </span>
    <span>Grados Escolares</span>
  </li>
  <li style={{ fontSize: '1.2rem', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span role="img" aria-label="Checkmark" style={{ marginRight: '8px' }}>
      ✅
    </span>
    <span>Matrimonios</span>
  </li>
  <li style={{ fontSize: '1.2rem', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span role="img" aria-label="Checkmark" style={{ marginRight: '8px' }}>
      ✅
    </span>
    <span>Año Nuevo</span>
  </li>
</ul>
            </div>
            <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '20px' }}>
              Nuestro equipo se encargará de que cada evento sea único y memorable. ¡Contáctanos para planificar tu próxima celebración!
            </p>
          </section>

          <div style={{ marginTop: '20px' }}>
            <Figura />
          </div>
          <section className="salon-call-to-action">
            <p>
              Llama al número {phoneNumber} y reserva tu evento especial con nosotros.
            </p>
          </section>
          <div
            initial="hidden"
            animate={showGallery ? "visible" : "hidden"}
            variants={variants}
            className="gallery-container"
            style={{ next: { color: "#0000" }, prev: { color: "#0000" } }}
          >
            <Gallery items={images} />
          </div>
        </main>
        <footer className="salon-footer" style={{ textAlign: 'center', marginTop: '20px' }}>
          <p style={{ fontSize: '1.2rem', color: '#333' }}>
            ¿Listo para vivir una experiencia única? ¡Reserva tu evento especial con nosotros ahora mismo!
          </p>
          <a
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: '#D32F2F',
              color: '#FFF',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              textDecoration: 'none',  // Eliminar subrayado para el enlace
            }}
            href="tel:6046041005"
            target="_blank"  // Abrir enlace en una nueva pestaña/ventana
            rel="noopener noreferrer"  // Recomendado por razones de seguridad cuando se usa target="_blank"
          >
            Reservar Ahora
          </a>
        </footer>
      </div>
    </Layout>
  );
};
export default Salon;
