
import React from "react";
import Layout from "../components/Layout/Layout";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Instagram, LocationOn, Phone } from "@mui/icons-material";
import Mapa from "../components/Layout/MapaC";
const phoneNumber1 = "604-6041005";
const phoneNumber2 = "3044936299";
const Contact = () => {
  return (
    <Layout>
      <Box sx={{ my: 5, mx: "auto", maxWidth: "800px", px: 2 }}>
        <Box
          sx={{
            height: "300px",
            width: "100%",
            margin: "0",
            padding: "0",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
            "@media (max-width: 768px)": {
              height: "200px",
              fontSize: "1.6rem",
            },
            "@media (max-width: 420px)": {
              height: "180px",
              fontSize: "1.3rem",
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginTop: "1rem",
              fontSize: "6rem",
              fontWeight: "bold",
              color: "#D32F2F",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              "@media (max-width: 768px)": {
                fontSize: "3.6rem",
              },
              "@media (max-width: 420px)": {
                fontSize: "3rem",
              },
              "@media (max-width: 390px)": {
                fontSize: "2.4rem",
              },
            }}
          >
            Asia Restaurante
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          ¡Te invitamos a tener una excelente experiencia gastronómica en el
          Restaurante Asia! Estamos más que felices de recibirte en nuestro
          establecimiento.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Ven y disfruta de una amplia variedad de platillos
          asiáticos auténticos, con sabores exóticos que te transportarán al
          corazón de Asia.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Nuestro personal amable y servicial estará encantado
          de atenderte y asegurarse de que tengas una experiencia gastronómica
          memorable. ¡Eres más que bienvenido en el Restaurante Asia!
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", py: 4 }}>
        <TableContainer component={Paper} sx={{ width: "100%", maxWidth: "500px", mb: 4 }}>
          <Table aria-label="contact table">
            <TableHead sx={{ bgcolor: "#212121" }}>
              <TableRow>
                <TableCell sx={{ justifyContent: "center", alignItems: "center", color: "#ffffff" }} align="center">Contacto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#ffffff" }}>
                <TableCell
                  sx={{
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a href={`tel:${phoneNumber1}`} style={{ textDecoration: "none", color: "#005315", display: "flex", alignItems: "center" }}>
                    <Phone sx={{ color: "#03a9f4", mr: 1 }} /> {phoneNumber1}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow sx={{ bgcolor: "#ffffff" }}>
                <TableCell
                  sx={{
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a href={`tel:${phoneNumber2}`} style={{ textDecoration: "none", color: "#005315", display: "flex", alignItems: "center" }}>
                    <Phone sx={{ color: "#03a9f4", mr: 1 }} /> {phoneNumber2}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow sx={{ bgcolor: "#ffffff" }}>
                <TableCell sx={{ color: "#000000", display: "flex", justifyContent: "center", alignItems: "center", pt: 1 }}>
                  <Instagram sx={{ color: "#03a9f4", mr: 1 }} /> restaurante_asia
                </TableCell>
              </TableRow>
              <TableRow sx={{ bgcolor: "#ffffff" }}>
                <TableCell sx={{ color: "#000000", display: "flex", justifyContent: "center", alignItems: "center", pt: 1 }}>
                  <LocationOn sx={{ color: "#550000", mr: 1 }} /> Calle 16 30 - 145 Las Palmas Medellín
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

    <div className="domicilios-back-to-menu">
          <a href="/menu" style={{ textDecoration: 'none' }}>
            <button
              style={{
                display: 'inline-block',
                padding: '10px 20px',
                backgroundColor: 'var(--color-red)',
                color: '#fff',
                fontSize: '16px',
                borderRadius: '5px',
                marginTop: '20px',
                textDecoration: 'none',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
            >
              Menú
            </button>
          </a>
        </div>


<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px', // Ajusta el tamaño de fuente para pantallas más pequeñas
    color: '#888', // Cambia el color del texto a uno menos importante
    margin: '20px 0',
  }}
>
  <h1
    style={{
      fontSize: '18px', // Tamaño de fuente original
      margin: '0',
    }}
  >
   Arroz chino Medellín
  </h1>
</Box>
    
        <Mapa />
      </Box>
    </Layout>
  );
};
export default Contact;
