import React from "react";
import Layout from "./../components/Layout/Layout";
import "../styles/menu_cuadros.css";
import exportObject from "../data/data"; // Importa los datos directamente desde data

function CartaChino() {
  // Desestructura las variables importadas
  const { Cartachino } = exportObject;

  // Estilos para el título creativo
  const tituloCreativoStyles = {
    fontSize: "2rem", // Tamaño de fuente
    fontWeight: "bold", // Peso de fuente
    textAlign: "center", // Alineación de texto
    color: "#333", // Color de texto principal
    marginBottom: "20px", // Margen inferior
  };

  // Estilos para la bienvenida en chino
  const bienvenidaChinoStyles = {
    fontSize: "1.5rem", // Tamaño de fuente
    color: "#666", // Color de texto secundario
    marginBottom: "20px", // Margen inferior
    marginLeft: "10px", // Margen izquierdo
    marginRight: "10px", // Margen derecho
    textAlign: "center", // Alineación de texto
  };

  return (
    <Layout>
      <main>
        <div className="contenedor_menu">
          {/* Título creativo */}
          <h1 style={tituloCreativoStyles}>¡Bienvenido al Menú Chino!</h1>
          {/* Bienvenida en chino */}
          <p style={bienvenidaChinoStyles}>欢迎光临中国菜单</p>
        </div>
        {Cartachino.categorias.map((categoria, index) => (
          <div className="contenedor_menu" key={index} id={categoria.nombre}>
            <h2 className="titulo_seccion">{categoria.nombre}</h2>
            <div className="seccion_menu">
              {categoria.items.map((item, itemIndex) => (
                <div className="item_menu" key={itemIndex}>
                  <p className="nombre_plato">{item.nombre_plato}</p>
                  <p className="descripcion_plato">{item.descripcion_plato}</p>
                  <p className="precio_plato">
                    <span className="puntos">..................</span>
                    {item.precio_plato}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </main>
    </Layout>
  );
}

export default CartaChino;

