import React from 'react';
import imagen from '../../images/logo.webp';
import F from '../../img/MurallaChina.avif';

const estiloChino = {
  backgroundColor: 'rgba(255, 255, 255, 0.22)',
  backgroundImage: `url(${F})`,
  backgroundSize: '50%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '200px',
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px',
border: '2px solid transparent', // Borde inicialmente transparente

  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  fontFamily: 'KaiTi, Arial, sans-serif',
  color: '#8B4513',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  fontSize: '24px',
  animation: 'myAnimation 3s infinite',
};

const imagenEstilo = {
  maxWidth: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  height: 'auto',
  width: '80px',
  marginRight: '20px',  // Ajusta el margen derecho según tus necesidades
  borderRadius: '10px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
  opacity: 1,
  zIndex: 3,
};

const textoEstilo = {
  backgroundColor: 'rgba(0, 0, 0, 0.22)',
  marginTop: '20px',
  color: 'white',
  textAlign: 'center',
  borderRadius: '10px',
  lineHeight: '1.5',
  opacity: 1,
  zIndex: 3,
  fontWeight: 700,
};

const componenteContainer = {
  width: '100%',
  padding: '0 20px',
  boxSizing: 'border-box',
  opacity: 1,
  zIndex: 3,
};

class MiComponente extends React.Component {
  render() {
    return (
      <div style={componenteContainer}>
        <div style={estiloChino}>
          <div style={textoEstilo}>
            ¡Bienvenido a nuestro restaurante asiático!
            <br />
            Descubre sabores auténticos que te transportarán a Asia.
          </div>
          <img
            src={imagen}
            alt="logo restaurante asia"
            style={imagenEstilo}
          />
        </div>
      </div>
    );
  }
}

export default MiComponente;
