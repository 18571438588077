import React from "react";
import Layout from "./../components/Layout/Layout";

const Pagenotfound = () => {
  const pageNotFoundStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    marginTop: "100px",
  };

  const titleStyle = {
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    color: "#FF5722",
    // Puedes agregar estilos adicionales de estilo asiático aquí
  };

  return (
    <Layout>
      <div style={pageNotFoundStyle}>
        <h1 style={titleStyle}>Página no encontrada</h1>
        <p><a href="/">Volver al inicio.</a></p>
      </div>
    </Layout>
  );
};

export default Pagenotfound;

