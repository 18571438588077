import React from "react";
import "../../styles/Figura.css"; // Asegúrate de tener los estilos CSS necesarios
import figura from "../../images/figura.webp";

const Figura = () => {
  return (
    <div className="figura-container">
      <img
        src={figura}
        alt="Figura"
        className="figura-image"
      />
    </div>
  );
};

export default Figura;
