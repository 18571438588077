import React from "react";
import Layout from "../components/Layout/Layout";
import "../styles/menu_cuadros.css";
import {  useSelector } from "react-redux";
import {  Link } from 'react-router-dom';

import { useGetAllProductsQuery } from "../slices/productsApi";
import "../styles/Home.css"
import Figura from "../components/Piaces/Figura";
import { Box, Card, CardContent, CardMedia, Button, Typography } from "@mui/material";
import { BebiList } from "../data/data";
const Bebidas = () => {
  const { items: products, status } = useSelector((state) => state.products);

  const { data, error, isLoading } = useGetAllProductsQuery();
  const filteredProducts = data ? data.filter((bebidasProducts) => bebidasProducts.category === "bebidas") : [];
  console.log("Api", isLoading);

  return (
    <Layout>
          <div className="contenedor_menu" id="lomien" >
      <div className="home-containere">
        {status === "success" ? (
          <>
 <Typography
            variant="h1"
            sx={{
              marginTop: "0",
              fontSize: "6rem",
              fontWeight: "bold",
              textAlign:"center",
              color: "#D32F2F",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              "@media (max-width: 768px)": {
                fontSize: "3.6rem",
              },
              "@media (max-width: 420px)": {
                fontSize: "3rem",
              },
              "@media (max-width: 390px)": {
                fontSize: "2.4rem",
              },
            }}
          >
            Bebidas
          </Typography>
            <Link to="/menu" className="btn-regresare"
             sx={{
              marginTop: "1rem",
              fontSize: "6rem",
              textAlign:"center",
            }}
            >Regresar al Menú</Link>
<div style={{ marginTop: '20px' }}>
            <Figura />
          </div>


            <div className="products">
              {data && filteredProducts.map((bebidasProducts)  => (
    <div key={bebidasProducts.id} className="product">
    <span className="plato">{bebidasProducts.plato}</span>
                  <h3>{bebidasProducts.name}</h3>
                    <img src={bebidasProducts.image} alt={bebidasProducts.name} />
                    <div className="details">
                      <span>{bebidasProducts.desc}</span>
                    </div>
                    <div className="details">
                      <span className="price">${bebidasProducts.price}</span>
                    </div>
              
                  </div>
                ))}
            </div>
              <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {BebiList.map((menu) => (
            <Card
              sx={{
                maxWidth: "380px",
                width: "24rem",
                margin: "20px",
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "26rem",
                minHeight: "26rem",
              }}
              key={menu.name}
            >
              <Link to={menu.link}>
                <CardMedia
                  sx={{ height: "280px" }}
                  component={"img"}
                  src={menu.image}
                  alt={menu.name}
                  loading="lazy" // Agregar lazy load para la imagen
                />
              </Link>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  variant="h2"
                  component={"div"}
                  sx={{
                    marginBottom: "2px",
                    alignItems: "center",
                    fontSize: "1.8rem",
                    color: "#550000",
                  }}
                >
                  {menu.name}
                </Typography>
              <Typography variant="body2" style={{ fontSize: '1rem' }}>
  {menu.description}
</Typography>
                <Link to={menu.link}>
<Button
  style={{
    textAlign: "center",
    backgroundColor: "#D32F2F", // Un rojo oscuro que puede ser adecuado para un restaurante chino
    color: "#FFFFFF", // Texto en blanco para contraste
    textTransform: "lowercase", // Convertir el texto a minúsculas
    padding: "4px 8px", // Espaciado interior del botón
    borderRadius: "16px", // Borde redondeado
    fontSize: "0.8rem",
    border: "none",
    cursor: "pointer",
    marginTop: "8px", // Margen de 8px en todos los lados del botón
  }}
>
  {menu.gelemento}
</Button>

                </Link>
              </CardContent>
            </Card>
          ))}
        </Box>
          </>
        ) : status === "pending" ? (
          <p>Cargando...</p>
        ) : (
          <p>Se produjo un error inesperado...</p>
        )}
        <Link to="/menu" className="btn-regresare"
             sx={{
                          marginTop: "1rem",
              fontSize: "6rem",
              textAlign:"center",
            }}
            >Regresar al Menú</Link>
      </div>
         <div style={{ marginTop: '20px' }}>
            <Figura />
          </div>
      </div>
    </Layout>
   );
  };
  
export default Bebidas;
