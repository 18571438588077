import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Box, Typography, Link } from "@mui/material";
import entradasImage from '../../images/Recuperado.webp';
import Usa from '../../images/usa.webp';
import China from '../../images/3.webp';
const phoneNumber1 = "6046041005";
const phoneNumber2 = "3044936299";
const Footer = () => {
  return (
    <Box sx={{ bgcolor: "black", color: "white", p: 4 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* Columna de redes sociales */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              fontFamily: 'Karla sans-serif',
            }}
          >
<Typography variant="body2" sx={{ fontWeight: "bold", mb: 1, display: "flex", alignItems: "center", py: 1}}>
  <a href="" rel="nofollow" style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "none", color: "#ffffff", display: "flex", flexDirection: "column", alignItems: "center" }}>
    <img src={Usa} srcSet={`${Usa} 480w, ${Usa} 768w, ${Usa} 1200w`} alt="Bandera de Estados Unidos" style={{ borderRadius: "5%", width: "58px", height: "32px", marginBottom: "5px", transition: "border-radius 0.3s ease-in-out" }} />
  </a>
</Typography>
<Typography variant="body2" sx={{ fontWeight: "bold", mb: 1, display: "flex", alignItems: "center",  py: 0 }}>
  <a href="" rel="nofollow" style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "none", color: "#ffffff", display: "flex", flexDirection: "column", alignItems: "center" }}>
    <img src={China} srcSet={`${China} 480w, ${China} 768w, ${China} 1200w`} alt="Bandera de China" style={{ borderRadius: "5%", width: "58px", height: "32px", marginBottom: "5px", transition: "border-radius 0.3s ease-in-out" }} />
  </a>
</Typography>



          </Box>
        </Grid>

        {/* Columna de teléfonos */}
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2" sx={{ mb: 1,  fontWeight: "bold", fontSize: "14px"  }}>Teléfonos:</Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1}}>
              <a href={`tel:${phoneNumber1}`} style={{ fontSize: "24px", textDecoration: "none", color: "#ffffff" }}>
                {phoneNumber1}
              </a>
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1}}>
              <a href={`tel:${phoneNumber2}`} style={{ fontSize: "24px", textDecoration: "none", color: "#ffffff" }}>
                {phoneNumber2}
              </a>
            </Typography>
          </Box>
        </Grid>

        {/* Columna de cartas */}
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ textAlign: "center" }}>
          
  <Link
      href="https://www.instagram.com/asia_restaurante"
      target="_blank"
      rel="nofollow"
      sx={{
        textAlign: "center",
        display: "inline-block",
        textDecoration: "none",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <InstagramIcon
          sx={{
            fontSize: "44px",
            color: "black",
            borderRadius: "50%",
            padding: "8px",
            backgroundColor: "white",
            transition: "all 200ms",
          }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "14px", color: "white", }}>
          Síguenos en Instagram
        </Typography>
      </div>
    </Link>
  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "bold", marginTop: "8px", color: "white", display: "inline-block" }}>
        Calle 16 30 - 145 Las Palmas Medellín Colombia
      </Typography>
          </Box>
        </Grid>





<Grid item xs={12} sm={6} md={3}>

    <Box 
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "110px",
        margin: "0 auto", // Centra el contenido horizontalmente
        padding: "16px", // Agrega espacio alrededor del contenido
        textAlign: "center", // Centra el texto
      }}
    >
<a href="/Carta">
      <img
        src={entradasImage}
        alt="Imagen"
        style={{
          width: "90px", // Define el ancho de la imagen
          height: "84px", // Define la altura de la imagen
          maxHeight: "100%", // La imagen no superará el alto máximo del contenedor
        }}
      />
</a>
  

     
           <Typography variant="body2" sx={{ fontWeight: "bold", mb: 0, py: 0 }}>
              <a href="/Carta" style={{ fontSize: "34px", textDecoration: "none", color: "#D32F2F" }}>
                <span>Carta</span>
              </a>
            </Typography>
    </Box>
  
</Grid>




      </Grid>
      <Box
        sx={{
          textAlign: "center",
          pt: 3,
          "& p": {
            fontSize: "0.9rem",
            m: 0,
          },
        }}
      ></Box>
    </Box>
  );
};

export default Footer;
