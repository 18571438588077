import React from "react";
import Layout from "../../components/Layout/Layout";
import { categorizedProducts } from "../../products/products";
import "../../sty/cartaschinas.css";
import { Typography } from "@mui/material";
import Figura from "../../components/Piaces/Figura";
import { Link } from 'react-router-dom';

const Gaseosas = () => {
  const filteredGaseosas = categorizedProducts.gaseosas || [];
  const evenProducts = filteredGaseosas.filter((_, index) => index % 2 === 0);
  const oddProducts = filteredGaseosas.filter((_, index) => index % 2 !== 0);

  return (
    <Layout>
      <div className="contenedor_cartas">
       <div style={{ marginTop: '20px' }}>
            <Figura />
          </div>
        <div className="cartas-container">
          <h2 className="carta-title">Gaseosas</h2>
          <ul className="product-list">
            <div className="product-column">
              {evenProducts.map((product) => (
                <li className="product-item" key={product.id}>
                  <Typography variant="h3" className="product-name">{product.name}</Typography>
                  <p className="product-description">{product.desc}</p>
                  <p className="product-price"> ${product.price}</p>
                </li>
              ))}
            </div>
            <div className="product-column">
              {oddProducts.map((product) => (
                <li className="product-item" key={product.id}>
                  <Typography variant="h3" className="product-name">{product.name}</Typography>
                  <p className="product-description">{product.desc}</p>
                  <p className="product-price"> ${product.price}</p>
                </li>
              ))}
            </div>
          </ul>
        </div>
         <div style={{ marginTop: '20px' }}>
            <Figura />
          </div>
             <Link to="/bebidas" className="btn-regresare"
             sx={{
                          marginTop: "1rem",
              fontSize: "6rem",
              textAlign:"center",
            }}
            >Regresar al Menú</Link>
      </div>
    </Layout>
  );
};

export default Gaseosas;
