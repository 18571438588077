import React from "react";
import Layout from "../components/Layout/Layout";
import exportObject from "../data/data";
import "../styles/menu_cuadros.css";
function Carta() {
  const { Cartaespañol } = exportObject;

  return (
    <Layout>
      <main>
        <div className="contenedor_menu">
          <h1 className="titulo_creativo">¡Bienvenidos a la carta del Restaurante Asia!</h1>
          <p className="bienvenida_español">Explora nuestros deliciosos platos asiáticos.</p>
        </div>
        {Cartaespañol.categorias.map((categoria, index) => (
          <div className="contenedor_menu" key={index} id={categoria.nombre}>
            <h2 className="titulo_seccion">{categoria.nombre}</h2>
            <div className="seccion_menu">
              {categoria.items.map((item, itemIndex) => (
                <div className="item_menu" key={itemIndex}>
                  <p className="nombre_plato">{item.nombre_plato}</p>
                  <p className="descripcion_plato">{item.descripcion_plato}</p>
                  <p className="precio_plato">
                    <span className="puntos">..................</span>
                    {item.precio_plato}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </main>
    </Layout>
  );
}

export default Carta;
